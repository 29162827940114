/* eslint-disable @typescript-eslint/prefer-for-of */
import { Pipe, PipeTransform } from '@angular/core';
import { Router, NavigationEnd, RoutesRecognized, NavigationStart } from '@angular/router';
import { Location } from '@angular/common';
import { filter, pairwise } from 'rxjs/operators';
import { LocalStorageService } from '../services/local-storage.service';

@Pipe({
  name: 'defaultHref'
})
export class DefaultHrefPipe implements PipeTransform {
  lastUrl = '/u/home';
  currentUrl = '';
  constructor(
    private router: Router,
    private location: Location,
    private localstorage: LocalStorageService
  ) {
  }


  async transform(x: string) {
    this.currentUrl = this.router.url;
    // const splitUrl = url.split('/');


    // if (x.length) {
    //   for (let i = 0; i < parseInt(x, 10); i++) {
    //     splitUrl.pop();
    //   }
    // } else {
    //   splitUrl.pop();

    // }


    // this.router.events.subscribe((event) => {
    //   if (event instanceof NavigationEnd) {

    //     this.localstorage.get({ key: 'history' }).then((history: any) => {
    //       history = JSON.parse(history);

    //       history.push(event.urlAfterRedirects);
    //       // if (history[(history.length - 2)] !== this.currentUrl) {
    //       //   this.localstorage.set({ key: 'history', value: JSON.stringify(history) }).then(() => {
    //       //     
    //       //   });
    //       // }

    //       this.lastUrl = history[(history.length - 1)];
    //       
    //       


    //     }, () => {

    //     });
    //   }
    // });
    return this.lastUrl;
  }

}
