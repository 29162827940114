import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular';
import { BrowserTracing } from '@sentry/tracing';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

// dsn staging link
/* let dsn = 'https://69c0e0c2eebc40d8ae27c9842bdf91bf@o1286613.ingest.sentry.io/4504397951401984';

if (environment.production) {
  enableProdMode();

  // dsn production link
  dsn = 'https://68730c05d98e4c6aaf3cc7ee081a3c63@o1286613.ingest.sentry.io/6500617';
}
 */
/* Sentry.init({
  dsn,
  integrations: [
    new BrowserTracing({
      tracingOrigins: ['localhost', 'https://yourserver.io/api'],
      routingInstrumentation: Sentry.routingInstrumentation,
    }),
  ],
  // integrations: [new BrowserTracing()],
  // To set a uniform sample rate
  tracesSampleRate: 0.2,
  // eslint-disable-next-line prefer-arrow/prefer-arrow-functions
  beforeSend(event, hint) {
    if (hint.originalException === 'Timeout') {
      return null;
    }
    return event;
  }
});
Sentry.setContext('App Details',{ app: { version: environment.versionCode } }); */


platformBrowserDynamic()
  .bootstrapModule(AppModule)
  // .then(success => console.log(`Bootstrap success`))
  .catch(err => console.error(err));

