/* eslint-disable max-len */
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { AutoLoginGuard } from './guards/auto-login.guard';
import { IntroGuard } from './guards/intro.guard';
import { PinGuard } from './guards/pin.guard';
import { PageGuardGuard } from './guards/page-guard.guard';
import { CheckerGuard } from './guards/checker.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full'
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/auth/login/login.module').then(m => m.LoginPageModule),
    canLoad: [IntroGuard, AutoLoginGuard]
  },
  {
    path: 'email-reverification',
    loadChildren: () => import('./pages/email-reverification/email-reverification.module').then( m => m.EmailReverificationPageModule)
  },
  {
    path: 'intro',
    loadChildren: () => import('./pages/intro/intro.module').then(m => m.IntroPageModule),
  },
  {
    path: 'forgot-password',
    loadChildren: () => import('./pages/auth/forgot-password/forgot-password.module').then(m => m.ForgotPasswordPageModule),
  },
  {
    path: 'verification-code/:type',
    loadChildren: () => import('./pages/auth/verification-code/verification-code.module').then(m => m.VerificationCodePageModule),
  },
  {
    path: 'reset-password',
    loadChildren: () => import('./pages/reset-password/reset-password.module').then(m => m.ResetPasswordPageModule),
  },
  /* {
    path: 'apply-now',
    loadChildren: () => import('./pages/apply-now/apply-now.module').then(m => m.ApplyNowPageModule)
  }, */
  {
    path: 'register',
    loadChildren: () => import('./pages/auth/register/register.module').then(m => m.RegisterPageModule),
  },
  {
    path: 'register-thank-you',
    loadChildren: () => import('./pages/register-thank-you/register-thank-you.module').then( m => m.RegisterThankYouPageModule)
  },
  {
    path: 'u',
    loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardPageModule),
    canLoad: [AuthGuard],
  },
  {
    path: 'u',
    pathMatch: 'prefix',
    children: [
      {
        path: 'terms-of-service',
        loadChildren: () => import('./modals/terms-of-service/terms-of-service.module').then(m => m.TermsOfServicePageModule)
      },
      {
        path: 'home',
        pathMatch: 'prefix',
        children: [
          {
            path: 'claim-loan-terms-and-conditions',
            loadChildren: () => import('./pages/claim-loan-terms-and-conditions/claim-loan-terms-and-conditions.module').then(m => m.ClaimLoanTermsAndConditionsPageModule)
          },
          {
            path: 'amortization-table',
            loadChildren: () => import('./pages/amortization-table/amortization-table.module').then(m => m.AmortizationTablePageModule)
          },
          {
            path: 'loan-detail',
            loadChildren: () => import('./pages/loan-detail/loan-detail.module').then(m => m.LoanDetailPageModule)
          },
          {
            path: 'download-files',
            loadChildren: () => import('./pages/download-files/download-files.module').then(m => m.DownloadFilesPageModule)
          },
          {
            path: 'applied-loan-preview',
            loadChildren: () => import('./pages/applied-loan-preview/applied-loan-preview.module').then(m => m.AppliedLoanPreviewPageModule)
          },
          {
            path: 'complete-profile-personal',
            canActivate: [PageGuardGuard, CheckerGuard],
            loadChildren: () => import('./pages/complete-profile-personal/complete-profile-personal.module').then( m => m.CompleteProfilePersonalPageModule)
          },
          {
            path: 'complete-profile-income',
            canActivate: [PageGuardGuard, CheckerGuard],
            loadChildren: () => import('./pages/complete-profile-income/complete-profile-income.module').then( m => m.CompleteProfileIncomePageModule)
          },
          {
            path: 'loan-product',
            canActivate: [PageGuardGuard, CheckerGuard],
            loadChildren: () => import('./pages/loan-product/loan-product.module').then( m => m.LoanProductPageModule)
          },
          {
            path: 'loan-apply-details/lite-loan',
            canActivate: [PageGuardGuard, CheckerGuard],
            loadChildren: () => import('./pages/loan-apply-details/loan-apply-details.module').then( m => m.LoanApplyDetailsPageModule),
            data: {
              loanLabel: 'lite'
            }
          },
          {
            path: 'loan-apply-details/salary-loan',
            canActivate: [PageGuardGuard, CheckerGuard],
            loadChildren: () => import('./pages/loan-apply-details/loan-apply-details.module').then( m => m.LoanApplyDetailsPageModule),
            data: {
              loanLabel: 'salary'
            }
          },
          {
            path: 'loan-apply-details/personal-loan',
            canActivate: [PageGuardGuard, CheckerGuard],
            loadChildren: () => import('./pages/loan-apply-details/loan-apply-details.module').then( m => m.LoanApplyDetailsPageModule),
            data: {
              loanLabel: 'personal'
            }
          },
          {
            path: 'loan-apply-details/business-loan',
            canActivate: [PageGuardGuard, CheckerGuard],
            loadChildren: () => import('./pages/loan-apply-details/loan-apply-details.module').then( m => m.LoanApplyDetailsPageModule),
            data: {
              loanLabel: 'business'
            }
          },
          {
            path: 'loan-apply-details/small-business-loan',
            canActivate: [PageGuardGuard, CheckerGuard],
            loadChildren: () => import('./pages/loan-apply-details/loan-apply-details.module').then( m => m.LoanApplyDetailsPageModule),
            data: {
              loanLabel: 'small business'
            }
          },
          {
            path: 'loan-apply-details/e-commerce-loan',
            canActivate: [PageGuardGuard, CheckerGuard],
            loadChildren: () => import('./pages/loan-apply-details/loan-apply-details.module').then( m => m.LoanApplyDetailsPageModule),
            data: {
              loanLabel: 'e-commerce'
            }
          },
          {
            path: 'loan-apply-details/appliance-loan',
            canActivate: [PageGuardGuard, CheckerGuard],
            loadChildren: () => import('./pages/loan-apply-details/loan-apply-details.module').then( m => m.LoanApplyDetailsPageModule),
            data: {
              loanLabel: 'appliance'
            }
          },
          {
            path: 'loan-apply-details/collateral-loan',
            canActivate: [PageGuardGuard, CheckerGuard],
            loadChildren: () => import('./pages/loan-apply-details/loan-apply-details.module').then( m => m.LoanApplyDetailsPageModule),
            data: {
              loanLabel: 'collateral'
            }
          },
          {
            path: 'loan-apply-details/allottee-loan',
            canActivate: [PageGuardGuard, CheckerGuard],
            loadChildren: () => import('./pages/loan-apply-details/loan-apply-details.module').then( m => m.LoanApplyDetailsPageModule),
            data: {
              loanLabel: 'allottee'
            }
          },
          {
            path: 'loan-apply-details/motorcycle-loan',
            canActivate: [PageGuardGuard, CheckerGuard],
            loadChildren: () => import('./pages/loan-apply-details/loan-apply-details.module').then( m => m.LoanApplyDetailsPageModule),
            data: {
              loanLabel: 'motorcycle'
            }
          },
          {
            path: 'loan-apply-reference',
            canActivate: [PageGuardGuard, CheckerGuard],
            loadChildren: () => import('./pages/loan-apply-reference/loan-apply-reference.module').then( m => m.LoanApplyReferencePageModule)
          },
          {
            path: 'loan-apply-attachment',
            canActivate: [PageGuardGuard, CheckerGuard],
            loadChildren: () => import('./pages/loan-apply-attachment/loan-apply-attachment.module').then( m => m.LoanApplyAttachmentPageModule)
          },
          {
            path: 'loan-apply-review',
            canActivate: [PageGuardGuard, CheckerGuard],
            loadChildren: () => import('./pages/loan-apply-review/loan-apply-review.module').then( m => m.LoanApplyReviewPageModule)
          },
        ]
      },
      {
        path: 'loan-history',
        loadChildren: () => import('./pages/loan-history/loan-history.module').then(m => m.LoanHistoryPageModule)
      },
      /* {
        path: ':lastPath/loan/:type',
        loadChildren: () => import('./pages/loan/loan.module').then(m => m.LoanPageModule)
      }, */
      {
        path: 'notifications',
        loadChildren: () => import('./pages/notifications/notifications.module').then(m => m.NotificationsPageModule)
      },
      {
        path: 'delete-account',
        loadChildren: () => import('./pages/delete-account/delete-account.module').then(m => m.DeleteAccountPageModule)
      },
      /*
      {
        path: 'profile',
        loadChildren: () => import('./pages/profile/profile.module').then(m => m.ProfilePageModule),
      },
      {
        path: 'profile',
        pathMatch: 'prefix',
        children: [
          {
            path: 'complete-profile/update',
            loadChildren: () => import('./pages/complete-profile/complete-profile.module').then(m => m.CompleteProfilePageModule),
            data: {
              method: 'update'
            }
          },
        ]
      },
      */
      /* {
        path: 'account',
        loadChildren: () => import('./pages/account/account.module').then(m => m.AccountPageModule)
      }, */
      {
        path: ':lastPath/pin-settings',
        loadChildren: () => import('./pages/pin-settings/pin-settings.module').then(m => m.PinSettingsPageModule)
      },
      {
        path: 'pin-settings',
        loadChildren: () => import('./pages/pin-settings/pin-settings.module').then(m => m.PinSettingsPageModule)
      },
      {
        path: ':lastPath/pin-settings/setup-pin',
        loadChildren: () => import('./pages/setup-pin/setup-pin.module').then(m => m.SetupPinPageModule)
      },
      {
        path: 'pin-settings/setup-pin',
        loadChildren: () => import('./pages/setup-pin/setup-pin.module').then(m => m.SetupPinPageModule)
      },
      /* {
        path: 'history-logs',
        loadChildren: () => import('./pages/history-logs/history-logs.module').then(m => m.HistoryLogsPageModule)
      } */
    ],

  },

  {
    path: 'pin',
    loadChildren: () => import('./pages/auth/pin/pin.module').then(m => m.PinPageModule),
    canLoad: [PinGuard]
  },

  {
    path: 'bad-gateway',
    loadChildren: () => import('./pages/bad-gateway/bad-gateway.module').then(m => m.BadGatewayPageModule),
    canLoad: [IntroGuard, AutoLoginGuard]
  },
  {
    path: 'server-error',
    loadChildren: () => import('./pages/server-error/server-error.module').then(m => m.ServerErrorPageModule),
    canLoad: [IntroGuard, AutoLoginGuard]
  }, {
    path: 'something-went-wrong',
    loadChildren: () => import('./pages/something-went-wrong/something-went-wrong.module').then(m => m.SomethingWentWrongPageModule),
    canLoad: [IntroGuard, AutoLoginGuard]
  },
  {
    path: 'maintenance-page',
    loadChildren: () => import('./pages/maintenance-page/maintenance-page.module').then(m => m.MaintenancePagePageModule),
  },
  {
    path: '**',
    loadChildren: () => import('./pages/page-not-found/page-not-found.module').then(m => m.PageNotFoundPageModule)
  },
  {
    path: 'image-modal',
    loadChildren: () => import('./pages/image-modal/image-modal.module').then(m => m.ImageModalPageModule)
  },
  {
    path: 'cancellation-modal',
    loadChildren: () => import('./pages/cancellation-modal/cancellation-modal.module').then(m => m.CancellationModalPageModule)
  },
  {
    path: 'preview-file',
    loadChildren: () => import('./pages/preview-file/preview-file.module').then(m => m.PreviewFilePageModule)
  },
  {
    path: 'file-preview-modal',
    loadChildren: () => import('./pages/file-preview-modal/file-preview-modal.module').then(m => m.FilePreviewModalPageModule)
  },
  {
    path: 'privacy',
    loadChildren: () => import('./modals/privacy/privacy.module').then(m => m.PrivacyPageModule)
  },
  {
    path: 'register-province',
    loadChildren: () => import('./modals/register-province/register-province.module').then( m => m.RegisterProvincePageModule)
  },
  {
    path: 'loan-apply-attachment',
    loadChildren: () => import('./pages/loan-apply-attachment/loan-apply-attachment.module').then( m => m.LoanApplyAttachmentPageModule)
  },
  {
    path: 'loan-purpose-notice',
    loadChildren: () => import('./modals/loan-purpose-notice/loan-purpose-notice.module').then( m => m.LoanPurposeNoticePageModule)
  },
  {
    path: 'delete-account',
    loadChildren: () => import('./pages/delete-account/delete-account.module').then( m => m.DeleteAccountPageModule)
  },
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],

  exports: [RouterModule]
})
export class AppRoutingModule { }
