import { Injectable } from '@angular/core';
import { Route, Router, UrlSegment, UrlTree } from '@angular/router';
import { isPlatform } from '@ionic/angular';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PinGuard  {

  constructor(
    private router: Router,
  ) { }

  canLoad(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (isPlatform('mobileweb') || isPlatform('android')) {
      return true;
    }

    this.router.navigateByUrl('/login', { replaceUrl: true });
    return false;

  }
}
