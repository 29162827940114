import { Component, Input, OnInit,  ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { StatusBar, Style, BackgroundColorOptions } from '@capacitor/status-bar';
import { isPlatform } from '@ionic/angular';
import { UtilService } from 'src/app/services/util.service';


import { SwiperComponent } from 'swiper/angular';

@Component({
  selector: 'app-file-preview-modal',
  templateUrl: './file-preview-modal.page.html',
  styleUrls: ['./file-preview-modal.page.scss'],
})

export class FilePreviewModalPage implements OnInit {

  @ViewChild('slider', { static: false }) slider?: SwiperComponent;

  @Input() url: any;
  @Input() fileName: string;
  @Input() type: string;

  isIframeLoaded = false;
  defaultImage = 'assets/images/placeholder.png';

  isBlob = true;

  constructor(
    private util: UtilService,
    public sanitizer: DomSanitizer
  ) {

  }

  ngOnInit() {
    if (!this.url.includes('blob:')) {
      this.isBlob = false;
    } else {
      this.isIframeLoaded = true;
    }
  }

  ionViewWillEnter() {
    if (!isPlatform('mobileweb') && isPlatform('android')) {
      try {
        StatusBar.setStyle({ style: Style.Light });
        const opts: BackgroundColorOptions = {
          color: '#2c272dd6',
        };
        StatusBar.setBackgroundColor(opts);
      } catch (error) {

      }

    }
  }

  isLoaded() {
    this.isIframeLoaded = true;
  }



  ionViewDidLeave() {
    if (!isPlatform('mobileweb') && isPlatform('android')) {
      try {
        StatusBar.setStyle({ style: Style.Light });
        const opts: BackgroundColorOptions = {
          color: '#FFFFFF',
        };
        StatusBar.setBackgroundColor(opts);
      } catch (error) {

      }

    }
  }



  close() {
    if (this.util.currentModal) {
      try {
        this.util.currentModal.dismiss().then(() => {
          this.util.currentModal = null;
        });
      } catch (error) { }
    }
  }

}
