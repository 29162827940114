import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable, forkJoin, of,Subject } from 'rxjs';
import { take, takeUntil, tap, catchError, finalize } from 'rxjs/operators';
import { LoanService } from '../services/loan.service';
import { LoadingService } from 'src/app/services/loading.service';
import { UtilService } from 'src/app/services/util.service';
import { BorrowerService } from 'src/app/services/borrower.service';

@Injectable({
  providedIn: 'root'
})
export class CheckerGuard  {
  isSubscriptionsDestroyed$: Subject<boolean> = new Subject<boolean>();

  steps = [
    {
      title: 'Personal',
      active: false,
      status: '',
      activeIcon: false
    },
    {
      title: 'Source of Income',
      active: false,
      status: '',
      activeIcon: false
    },
    {
      title: 'Loan Product',
      active: false,
      status: '',
      activeIcon: false
    },
    {
      title: 'Loan & Bank',
      active: false,
      status: '',
      activeIcon: false
    }
  ];

  loanStatus: any;

  private isPageLoaded = false;

  constructor(
    private router: Router,
    private loanService: LoanService,
    private loading: LoadingService,
    public util: UtilService,
    private borrower: BorrowerService,
  ) { }

  canActivate(): Observable<boolean> {
    return this.fetchBorrowerDetails();
  }

  fetchBorrowerDetails(): Observable<boolean> {
    if (this.isPageLoaded) {
      // If the page is already loaded, return an observable with a resolved value
      // If this remove CheckerGuard will run on loop
      return of(true);
    }

    this.loading.simpleLoader({});

    return forkJoin({
      validateTab: this.loanService.validateTabInfo(),
      validateLoanTab: this.loanService.validateTab(),
      loanStatus: this.borrower.loanStatus.pipe(take(1)),
    }).pipe(
      takeUntil(this.isSubscriptionsDestroyed$),
      take(1),
      tap((response: any) => {
        const { validateTab, validateLoanTab, loanStatus } = response;

        if (validateTab.personal === true) {
          this.router.navigate(['/u/home/complete-profile-personal']);
        } else if (validateTab.sourceIncome === true) {
          this.router.navigate(['/u/home/complete-profile-income']);
        } else if (validateTab.loanProduct === true) {
          this.router.navigate(['/u/home/loan-product']);
        } else if (validateLoanTab.loan === true) {
          this.router.navigate(['/u/home/loan-apply-details/'+loanStatus.label.replace(' ','-')+'-loan']);
        } else if (validateLoanTab.reference === true) {
          if(loanStatus.branch === 'manila'){
            this.router.navigate(['/u/home/loan-apply-reference']);
          }
        } else if (validateLoanTab.attachment === true) {
          this.router.navigate(['/u/home/loan-apply-attachment']);
        } else {
          // eslint-disable-next-line max-len
          if (loanStatus.status === 'incomplete_requirement') {
            this.router.navigate(['/u/home/loan-apply-review']);
          } else {
            this.router.navigate(['/u/home']);
          }
        }
        // Set true to indicate that the page has been loaded
        this.isPageLoaded = true;
      }),
      catchError(err => {
        if (err.status !== 200) {
          this.util.showAllErrorToast(err);
          this.util.presentErrorAlert('Error fetching your profile. Please try again.');
        }
        return of(false);
      }),
      finalize(() => {
        this.loading.dismissLoader();
      })
    );
  }
}
