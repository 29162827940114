import { Component, OnInit, CUSTOM_ELEMENTS_SCHEMA, NgModule, Input } from '@angular/core';
import { GlobalService } from 'src/app/services/global.service';
import { UtilService } from 'src/app/services/util.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {

  @Input() bottomFooterClass: string;
  @Input() footerContentClass: string;
  @Input() showOtherInfo: boolean;


  constructor(
    public global: GlobalService,
    public util: UtilService,
  ) { }

  ngOnInit() { }

}
