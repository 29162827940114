import { Component, Host, Input, OnInit } from '@angular/core';
import { GlobalService } from 'src/app/services/global.service';
import { HostService } from 'src/app/services/host.service';

@Component({
  selector: 'app-footer-two',
  templateUrl: './footer-two.component.html',
  styleUrls: ['./footer-two.component.scss'],
})
export class FooterTwoComponent implements OnInit {

  @Input() bottomFooterClass: string;

  constructor(
    public global: GlobalService,
    public host: HostService
  ) { }

  ngOnInit() { }

}
