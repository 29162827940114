import { Pipe, PipeTransform } from '@angular/core';
import { isPlatform } from '@ionic/angular';

@Pipe({
  name: 'display'
})
export class DisplayPipe implements PipeTransform {

  transform(size: string): any {
    const display = window.innerWidth;
    switch (size) {
      case 'mobileweb':
        if (isPlatform('mobileweb')) {
          return true;
        }
        break;

      case 'mobile':
        if (display < 769) {
          return true;
        }
        break;
      case 'desktop':
        if (display > 768) {
          return true;
        }
        break;
      default:
        break;
    }
  }

}
