import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { Router, RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
// import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { CurrencyPipe, DatePipe } from '@angular/common';
import { SharedModule } from './modules/shared/shared/shared.module';
import { SwiperModule } from 'swiper/angular';
import { NavigationService } from './services/navigation.service';
import { Toast } from '@awesome-cordova-plugins/toast/ngx';
import { FileOpener } from '@awesome-cordova-plugins/file-opener/ngx';
import { ErrorHandlingService } from './services/error-handling.service';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { SanitizePipe } from './pipes/sanitize.pipe';
import { RemoveCurrencyFormatPipe } from './pipes/remove-currency-format.pipe';
import { Device } from '@awesome-cordova-plugins/device/ngx';
import * as Sentry from '@sentry/angular';
import { CustomCurrencyPipePipe } from './pipes/custom-currency-pipe.pipe';

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        IonicModule.forRoot({
            mode: 'ios',
            backButtonText: '',
            swipeBackEnabled: false
        }),
        AppRoutingModule,
        HttpClientModule,
        // SweetAlert2Module.forRoot(),
        SharedModule,
        SwiperModule,
        LazyLoadImageModule,
    ],
    providers: [
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ErrorHandlingService,
            multi: true
        },
        CurrencyPipe,
        CustomCurrencyPipePipe,
        RemoveCurrencyFormatPipe,
        DatePipe,
        NavigationService,
        Toast,
        FileOpener,
        SanitizePipe,
        Device,
       /*  {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler({
                showDialog: false,
            }),
        },
        {
            provide: Sentry.TraceService,
            deps: [Router],
        },
        {
            provide: APP_INITIALIZER,
            useFactory: () => () => { },
            deps: [Sentry.TraceService],
            multi: true,
        }, */
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
