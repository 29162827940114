import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { catchError, finalize, of, Subject, switchMap, takeUntil, tap } from 'rxjs';
import { LoadingService } from 'src/app/services/loading.service';
import { UtilService } from 'src/app/services/util.service';

@Component({
  selector: 'app-terms-of-service',
  templateUrl: './terms-of-service.page.html',
  styleUrls: ['./terms-of-service.page.scss'],
})
export class TermsOfServicePage implements OnInit, OnDestroy {

  @Input() submitButton: any;
  @Input() closeCallback: any;

  template: any;
  description: any;

  isSubscriptionsDestroyed$: Subject<boolean> = new Subject<boolean>();


  constructor(
    public util: UtilService,
    private loading: LoadingService,
  ) { }

  async ngOnInit() {
    await this.loading.simpleLoader({});
    this.util.getTermsAndCondition()
      .pipe(
        takeUntil(this.isSubscriptionsDestroyed$),
        tap({
          next: async (res: any) => {
            const template = [];
            for (const key in res) {
              if (Object.prototype.hasOwnProperty.call(res, key)) {
                if (key !== 'description') {
                  template.push(res[key]);
                }
              }
            }
            this.template = template;
            this.description = res.description;
          }
        }
        ),
        catchError(() => of([])),
        finalize(() => {
          this.loading.dismissLoader();
        })).subscribe();
  }

  ngOnDestroy() {
    this.isSubscriptionsDestroyed$.next(true);
    this.isSubscriptionsDestroyed$.unsubscribe();
  }

  async next() {
    await this.close(false);
    if (this.closeCallback) {
      this.closeCallback();
    }
  }

  close(triggerCallback = true) {

    if (this.util.currentModal) {
      this.util.currentModal.dismiss().then(() => {
        this.util.currentModal = null;
      });
    }

    if (triggerCallback && this.closeCallback) {
      this.closeCallback();
    }

  }

}
