/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable, OnDestroy } from '@angular/core';
import { LocalStorageService } from './local-storage.service';
import Swal from 'sweetalert2';
import { Toast } from '@awesome-cordova-plugins/toast/ngx';
import { catchError, Observable, Subject, tap, switchMap, takeUntil, of, finalize, take } from 'rxjs';
import { AlertController, isPlatform, ModalController } from '@ionic/angular';
import { GlobalService } from './global.service';
import { StatusBar, Style, BackgroundColorOptions } from '@capacitor/status-bar';
import { AuthenticationService } from './authentication.service';
import { TermsOfServicePage } from '../modals/terms-of-service/terms-of-service.page';
import { PrivacyPage } from '../modals/privacy/privacy.page';
import { LoanService } from './loan.service';
import { LoadingService } from './loading.service';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { HostService } from './host.service';
import { ImageModalPage } from '../pages/image-modal/image-modal.page';
import { FilePreviewModalPage } from '../pages/file-preview-modal/file-preview-modal.page';
import { RegisterProvincePage } from '../modals/register-province/register-province.page';
import { LoanPurposeNoticePage } from '../modals/loan-purpose-notice/loan-purpose-notice.page'

@Injectable({
  providedIn: 'root'
})
export class UtilService implements OnDestroy {

  /**
   * termsOfServiceModals
   */
  currentModal: any;
  isSubscriptionsDestroyed$: Subject<boolean> = new Subject<boolean>();

  private host;
  private httpOptionsWithAuth;

  constructor(
    private localStorageService: LocalStorageService,
    private toastNative: Toast,
    private alertController: AlertController,
    private global: GlobalService,
    private auth: AuthenticationService,
    private modalController: ModalController,
    private loanService: LoanService,
    private loading: LoadingService,
    private router: Router,
    private http: HttpClient,
    private hostService: HostService,
  ) {
    this.host = this.hostService.endpoint;
    this.httpOptionsWithAuth = this.auth.httpOptionsWithAuth;
  }

  ngOnDestroy(): void {
    this.isSubscriptionsDestroyed$.next(true);
    this.isSubscriptionsDestroyed$.unsubscribe();
  }

  beforeUnload(event: any) {
    event.returnValue = `You have unsaved changes, leave anyway?`;
  };

  sendMessage({ host = this.hostService.endpoint, body, httpOptionsMultipartWithAuth = this.auth.httpOptionsMultipartWithAuth }): Observable<any> {
    return this.http.post(`${host}/api/vlm2/borrower/send/inquiry`, body, httpOptionsMultipartWithAuth);
  }

  getTermsAndCondition({ host = this.hostService.endpoint } = {}) {
    return this.http.get(`${host}/api/vlm2/terms-of-use`);
  }

  getPrivacyPolicy(host = this.hostService.endpoint) {
    return this.http.get(`${host}/api/vlm2/privacy-policy`);
  }

  getContactUsSubjects(host = this.hostService.endpoint, httpOptionsWithAuth = this.auth.httpOptionsWithAuth) {
    return this.http.get(`${host}/api/vlm2/borrower/inquiry/subject`, httpOptionsWithAuth);
  }

  async logoutWhenIdle(global = this.global) {
    const { autoLogoutKey } = global.localStorageKeys;
    const autoLogoutConfig = {
      type: 'idle',
      title: `You've been logged out`,
      message: 'You have been automatically logged out due to inactivity.',
    };
    await this.localStorageService.set({ key: autoLogoutKey, value: JSON.stringify(autoLogoutConfig) });
    window.removeEventListener('beforeunload', this.beforeUnload, true);
    this.auth.clearLoginCache();
    window.location.replace('/login');
  }

  async showToastNative(message = '', duration = '5000', position = 'center') {
    this.toastNative.show(message, duration, position).pipe(
      takeUntil(this.isSubscriptionsDestroyed$))
      .subscribe();
  }

  async showToast({ title = null, text = null, icon = 'success', html = null, color = 'primary', timer = 5000, id = new Date().getTime(), disablePadding = false }) {
    const iconAny: any = (icon === 'none') ? null : icon;
    const isMobile = (isPlatform('mobileweb') || isPlatform('android')) ? true : false;

    if (isMobile) {
      Swal.fire({
        icon: iconAny,
        toast: true,
        position: 'bottom',
        showConfirmButton: false,
        timer,
        width: 600,
        title,
        text,
        html,
        background: '#525252',
        color: 'white',
        customClass: {
          popup: disablePadding ? '' : 'mb-[68px]'
        }
      });
    }

    if (!isMobile) {
      Swal.fire({
        icon: iconAny,
        toast: true,
        position: 'bottom-left',
        showConfirmButton: false,
        timer,
        timerProgressBar: false,
        title,
        text,
        html,
        background: '#2d2d2d',
        color: 'white',
      });
    }
  }

  async rateUs() {
    const alert = await this.alertController.create({
      header: 'Rate This App',
      // eslint-disable-next-line max-len
      message: `Enjoying using this app? Take a moment to rate it! Thank you for your support!`,
      backdropDismiss: false,
      mode: 'ios',
      keyboardClose: false,
      buttons: [
        {
          text: 'Later',
          role: 'cancel',
          cssClass: 'secondary'
        }, {
          text: 'No,Thanks',
          handler: async () => {
            this.localStorageService.set({ key: 'is_rate_us_seen', value: true });
            // await alert.dismiss();
          },
          cssClass: 'secondary'
        }, {
          text: 'Rate Now',
          handler: async () => {
            window.open('market://details?id=com.vidalia.lending', '_blank');
            await this.localStorageService.set({ key: 'is_rate_us_seen', value: true });
            // await alert.dismiss();
          }
        }
      ]
    });
    await alert.present();
  }

  showWelcomeToNewApp(global = this.global) {
    const { isWelcomeHidden } = global.localStorageKeys;
    const html = `
    <div class="text-left px-8 xs:px-2 text-11px">
    <b class="text-13px text-F9AC18 mb-2">Enjoy better online experience with these new features:</b><br>
    • Fresh and friendly user interface<br>
    • Monitor your Payment Schedule<br>
    <br>
    <br>
    <div class="text-11">Have you downloaded the Vidalia Lending Mobile app? Download now for a
    more seamless borrowing experience!</div><br>
    <a class="mb-6" href='https://play.google.com/store/apps/details?id=com.vidalia.lending'>
    <img alt='Get it on Google Play' width="150" class="m-auto w-40"
      src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png' />
    </a>
    </div>`;

    Swal.fire({
      allowOutsideClick: false,
      customClass: {
        closeButton: 'swal-close',
        title: 'swal-title-welcome',
        htmlContainer: 'swal-container text-black',
      },
      showCloseButton: true,
      showConfirmButton: false,
      imageWidth: 250,
      imageUrl: './../../assets/images/ae8abd16f2f898276052ab8050e37e8b.png',
      title: 'Welcome to the new Vidalia Lending Online!',
      html,
      imageAlt: 'Welcome!',
    }).then(() => { this.localStorageService.set({ key: isWelcomeHidden, value: true }); }
    );
  }

  async autoLogoutAlert({ title, type, message, global = this.global }) {
    const { autoLogoutKey } = global.localStorageKeys;
    const alert = await this.alertController.create({
      header: title,
      subHeader: '',
      message: `${message}`,
      backdropDismiss: false,
      mode: 'ios',
      keyboardClose: false,
      buttons: [{
        text: 'Close',
        role: 'cancel',
        cssClass: 'secondary',
        handler: () => {
          this.localStorageService.remove({ key: autoLogoutKey }).then(() => { }, () => { });
        }
      },]
    });
    await alert.present();
  }

  /**
   *
   * @param color
   * @param style
   */
  setNativeTopBarColor({ color = '#FCF1D1', style = Style.Light }) {

    StatusBar.setStyle({ style }).catch(() => { });
    const opts: BackgroundColorOptions = {
      color,
    };
    StatusBar.setBackgroundColor(opts).catch(() => { });

  }

  showLoginAttempExceeded() {
    Swal.fire({
      customClass: {
        closeButton: 'swal-close',
        title: 'swal-title-denied',
        htmlContainer: 'swal-container',
      },
      showCloseButton: true,
      showConfirmButton: false,
      imageUrl: './../../assets/images/login-attempt-exceed.png',
      imageWidth: 250,
      title: 'Login Attempt Exceed',
      html: 'You have reached the maximum allowed login attempt. Please try again after 1 hour.',
      imageAlt: 'SORRY!',
    });
  }

  async termsOfServiceModal(submitButton = null, closeCallback = null) {
    const modalController = await this.modalController.create({
      backdropDismiss: closeCallback ? false : true,
      keyboardClose: closeCallback ? false : true,
      // canDismiss: closeCallback ? false : true,
      component: TermsOfServicePage,
      componentProps: {
        submitButton,
        closeCallback
      }
    });
    await modalController.present();
    return this.currentModal = modalController;
  }

  async privacyModal(submitButton = null, closeCallback = null) {
    const modalController = await this.modalController.create({
      backdropDismiss: closeCallback ? false : true,
      keyboardClose: closeCallback ? false : true,
      // canDismiss: true,
      component: PrivacyPage,
      componentProps: {
        submitButton,
        closeCallback
      }
    });
    await modalController.present();
    return this.currentModal = modalController;
  }

  showAllErrorToast(error, showDesktop = false) {

    if (isPlatform('desktop')) {
      if (!showDesktop) {
        return true;
      }
    }

    let x = '';
    switch (error.status) {
      case 409:
        for (const [key, value] of Object.entries(error.error.errors)) {
          if (value) {
            x += `• ${value}<br>`;
          }
        }
        this.showToast({ title: 'Something Went Wrong', html: x, icon: 'none' });
        break;
      case 422:
        for (const [key, value] of Object.entries(error.error.errors)) {
          if (value) {
            x += `• ${value}<br>`;
          }
        }
        this.showToast({ title: 'Warning', html: x, icon: 'none' });
        break;
      case 500:
        this.showToast({ title: 'Error', html: error.message, icon: 'error' });
        break;
    }
  }

  goBack() {
    history.back();
  }

  goto(path) {
    this.router.navigateByUrl(path);
  }

  // Redirect and refresh the page
  gotoWindowReplace(path) {
    window.location.replace(path);
  }

  async startCompleteProfile(loanStatus = null) {

    if (!!!loanStatus) {
      return true;
    }
    const { reapply } = loanStatus;
    if (reapply) {
      await this.loading.simpleLoader({});
      this.loanService.reapplyLoan()
        .pipe(
          takeUntil(this.isSubscriptionsDestroyed$),
          take(1),
          tap({
            next: async () => {
              window.location.replace('/u/home/complete-profile-personal');
            }
          }),
          catchError(err => {
            if (err.status !== 200) { }
            return of([]);
          }),
          finalize(() => {
            this.loading.dismissLoader();
          })).subscribe();
    }
    else {
      // this.router.navigateByUrl('/u/home/complete-profile-personal');
      window.location.replace('/u/home/complete-profile-personal');
    }
  }

  async presentErrorAlert(message = '') {
    const alert = await this.alertController.create({
      header: 'Oops! Something went wrong',
      message,
      backdropDismiss: false,
      mode: 'ios',
      keyboardClose: false,
      buttons: [{
        text: 'Close',
        role: 'cancel',
        cssClass: 'secondary',
        handler: () => {
          this.goBack();
        }
      },]
    });
    await alert.present();
  }

  async showErrorAlert(params) {
    const { refresh } = params;
    let { message, header, subHeader } = params;
    if (!header) {
      header = 'Oops! Something went wrong';
    }

    if (!subHeader) {
      subHeader = 'Please try again later.';
    }

    if (!message) {
      message = 'Oops! Something went wrong';
    }

    const buttons = [
      {
        text: 'Close',
        role: 'cancel',
        cssClass: 'secondary',
      }];
    if (refresh) {
      const x = {
        text: 'Restart',
        role: '',
        cssClass: '',
        handler: () => {
          window.location.reload();
        }
      };
      buttons.push(x);
    }

    const alert = await this.alertController.create({
      header,
      subHeader,
      message,
      backdropDismiss: false,
      mode: 'ios',
      keyboardClose: false,
      buttons
    });
    await alert.present();

  }

  formatBytes(bytes, decimals = 2) {
    if (bytes === 0) { return '0 Bytes'; }

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  showUnderReview(showCloseButton = true) {
    let timer = 0;
    let secondsLeft = 20;
    let timerFunction;
    Swal.fire({
      customClass: {
        closeButton: 'swal-close',
        title: 'swal-title-review',
        htmlContainer: 'swal-container',
      },
      showCloseButton,
      allowOutsideClick: showCloseButton,
      showConfirmButton: false,
      imageUrl: './../../assets/images/review-application.gif',
      imageWidth: 150,
      title: 'UNDER REVIEW!',
      html:
        `Your application is subject to review by our system.
        <br> <span class="font-bold">
        Please wait for a few seconds. This message will close automatically. <b>20</b></span>`
      ,
      didOpen: () => {
        const b = Swal.getHtmlContainer().querySelector('b');
        timerFunction = setInterval(async () => {
          if (secondsLeft > 0) {
            secondsLeft = secondsLeft - 1;
          }
          timer = secondsLeft;
          b.textContent = timer.toString();
          if (timer <= 0) {
            await clearInterval(timerFunction);

          }
        }, 1000);
      },
    });
  }

  showApproved() {
    Swal.fire({
      customClass: {
        closeButton: 'swal-close',
        title: 'swal-title-approved',
        htmlContainer: 'swal-container',
      },
      showCloseButton: true,
      showConfirmButton: false,
      imageUrl: './../../assets/images/application-approve.png',
      imageWidth: 250,
      title: 'CONGRATULATIONS!',
      html: 'Your applied loan was approved! You can now claim your loan.',
      imageAlt: 'CONGRATULATIONS!',
    });
  }

  async previewAttachment(file, overrideType = null) {

    const { fileName, url, routerOutlet } = file;
    const fileType = await (/[.]/.exec(fileName)) ? /[^.]+$/.exec(fileName) : undefined;

    if (!fileType) {
      return '?';
    }
    const fileTypeRaw = fileType[0].toLocaleLowerCase();

    switch (fileTypeRaw) {
      case 'jpg':
      case 'jpeg':
      case 'gif':
      case 'png':
        this.previewImageModal({ url, fileName, routerOutlet });
        break;
      case 'pdf':
      case 'doc':
      case 'docx':
        this.previewFileModal({ url, fileName, routerOutlet });
        break;
    }
  }

  async previewImageModal(params) {
    const { url, fileName } = params;
    const routerOutlet = params.routerOutlet;
    const modalController = await this.modalController.create({
      // showBackdrop: false,
      canDismiss: true,
      component: ImageModalPage,
      // presentingElement: routerOutlet,
      componentProps: {
        url,
        fileName,
      }
    });

    await modalController.present();
    return this.currentModal = modalController;
  }

  async previewFileModal(params) {
    const { url, fileName, type } = params;
    const routerOutlet = params.routerOutlet;
    const modalController = await this.modalController.create({
      // showBackdrop: false,
      canDismiss: true,
      component: FilePreviewModalPage,
      // presentingElement: routerOutlet,
      componentProps: {
        url,
        fileName,
        type
      }
    });
    await modalController.present();
    return this.currentModal = modalController;
  }

  async srollDownToProceedNotice() {
    const alert = await this.alertController.create({
      header: 'SCROLL DOWN',
      message:
        'Before you can proceed you must scroll to accept the latest Terms  & Privacy Policy',
      buttons: [
        {
          text: 'OKAY, GOT IT',
        },
      ],
    });
    return alert.present();
  }

  getAboutDetails() {
    return this.http.get(`${this.host}/api/vlm2/details`, this.httpOptionsWithAuth);
  }

  loanCardImage(loanLabel) {
    let value;
    switch (loanLabel) {
      case 'lite':
        value = 'mobile-liteloan.png';
        break;
      case 'salary':
        value = 'mobile-salaryloan.png';
        break;
      case 'personal':
        value = 'mobile-personalloan.png';
        break;
      case 'business':
        value = 'mobile-business.png';
        break;
      case 'small business':
        value = 'mobile-smallbusiness.png';
        break;
      case 'e-commerce':
        value = 'mobile-e-commerce-loan.png';
        break;
      case 'collateral':
        value = 'mobile-collateralloan.png';
        break;
      case 'appliance':
        value = 'mobile-appliancesloan.png';
        break;
    }
    return value;
  };

  async registerProvinceModal(submitButton = null, closeCallback = null) {
    const modalController = await this.modalController.create({
      // backdropDismiss: closeCallback ? false : true,
      // keyboardClose: closeCallback ? false : true,
      // canDismiss: closeCallback ? false : true,
      component: RegisterProvincePage,
      cssClass: 'register-province-container',
      componentProps: {
        submitButton,
        closeCallback
      }
    });
    await modalController.present();
    return this.currentModal = modalController;
  }

  loanStatusNotDisabled() {
    const loanStatus = 'incomplete_requirement';
    return loanStatus;
  }

  async leavePageRedirection(isGoBackPresented = false, link = '') {
    const alert = await this.alertController.create({
      header: 'Confirm Navigation',
      message: 'Leaving this page will lose your changes. Are  you sure you want to leave this page',
      backdropDismiss: false,
      mode: 'ios',
      keyboardClose: false,
      buttons: [
        {
          text: 'Stay on this Page',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            isGoBackPresented = false;
          }
        }, {
          text: 'Leave this Page',
          handler: () => {
            window.removeEventListener('beforeunload', this.beforeUnload, true);
            this.goto(link);
          }
        }
      ]
    });

    if (!isGoBackPresented) {
      await alert.present().then(() => {
        isGoBackPresented = true;
      }, () => {
        isGoBackPresented = false;
      });
    }
  }

  incomeEmploymentNotice(showCloseButton = true) {
    Swal.fire({
      customClass: {
        closeButton: 'swal-close',
        title: 'swal-title-review',
        htmlContainer: 'swal-container',
      },
      showCloseButton,
      allowOutsideClick: showCloseButton,
      showConfirmButton: false,
      imageUrl: './../../assets/images/warning.png',
      imageWidth: 150,
      title: 'Loan Purpose Notice',
      html:
        `You must have an existing business to proceed.`
      ,
    });
  }

  showDeleteAccount(showCloseButton = true) {
    let timer = 0;
    let secondsLeft = 10;
    let timerFunction;
    Swal.fire({
      customClass: {
        closeButton: 'swal-close',
        title: 'swal-title-review',
        htmlContainer: 'swal-container',
      },
      showCloseButton,
      allowOutsideClick: showCloseButton,
      showConfirmButton: false,
      imageUrl: './../../assets/images/delete-application.gif',
      imageWidth: 150,
      title: 'Deleting your records',
      html:
        `You will be directed to the log-in page.
        <br> <span class="font-bold">
        Please wait for a few seconds. This message will close automatically. <b>10</b></span>`
      ,
      didOpen: () => {
        const b = Swal.getHtmlContainer().querySelector('b');
        timerFunction = setInterval(async () => {
          if (secondsLeft > 0) {
            secondsLeft = secondsLeft - 1;
          }
          timer = secondsLeft;
          b.textContent = timer.toString();
          if (timer <= 0) {
            await clearInterval(timerFunction);

          }
        }, 1000);
      },
    });
  }

}
