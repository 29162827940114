import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'whatFileType'
})
export class WhatFileTypePipe implements PipeTransform {

  transform(fileName: any): any {

    const fileType = (/[.]/.exec(fileName)) ? /[^.]+$/.exec(fileName) : undefined;
    if (fileType[0]) {
      return fileType[0].toLowerCase();
    }

    return fileType;
  }

}
