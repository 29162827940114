/* eslint-disable max-len */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { AuthenticationService } from './authentication.service';
import { HostService } from './host.service';
import { tap } from 'rxjs/operators';
import { LocalStorageService } from './local-storage.service';


@Injectable({
  providedIn: 'root'
})
export class BorrowerService {

  details: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  notifications: BehaviorSubject<any> = new BehaviorSubject(null);
  isNotificationLoading$: BehaviorSubject<any> = new BehaviorSubject<any>(true);
  loanStatus: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  isPinEnabled: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  loanProducts: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  deleteState: BehaviorSubject<any> = new BehaviorSubject(null);

  constructor(
    private http: HttpClient,
    private hostService: HostService,
    private auth: AuthenticationService,
    private localStorageService: LocalStorageService,
  ) { }

  getEmploymentStatus(): Observable<any> {
    const host = this.hostService.endpoint;
    const httpOptionsWithAuth = this.auth.httpOptionsWithAuth;
    return this.http.get(`${host}/api/vlm2/borrower/employment/status`, httpOptionsWithAuth);
  }

  getLoanStatus(): Observable<any> {
    const host = this.hostService.endpoint;
    const httpOptionsWithAuth = this.auth.httpOptionsWithAuth;
    return this.http.get(`${host}/api/vlm2/loan/status`, httpOptionsWithAuth).pipe(tap(response => {
      if (response) {
        new LocalStorageService().set({ key: 'loan_status', value: JSON.stringify(response) });
        this.loanStatus.next(response);
      }
    }));
  }

  getRelation({ host = this.hostService.endpoint, httpOptionsWithAuth = this.auth.httpOptionsWithAuth } = {}): Observable<any> {
    return this.http.get(`${host}/api/vlm2/loan/reference/relation`, httpOptionsWithAuth);
  }

  getReference({ host = this.hostService.endpoint, loanType, httpOptionsWithAuth = this.auth.httpOptionsWithAuth }): Observable<any> {
    return this.http.get(`${host}/api/vlm2/loan/${loanType}/reference`, httpOptionsWithAuth);
  }

  getResidenceType({ host = this.hostService.endpoint, httpOptionsWithAuth = this.auth.httpOptionsWithAuth } = {}): Observable<any> {
    return this.http.get(`${host}/api/vlm2/borrower/residence/type`, httpOptionsWithAuth);
  }


  getContactDetails() {
    const host = this.hostService.endpoint;
    const httpOptionsWithAuth = this.auth.httpOptionsWithAuth;
    return this.http.get(`${host}/api/vlm2/borrower/details`, httpOptionsWithAuth);
  }


  getBusinessCategory({ host = this.hostService.endpoint, httpOptionsWithAuth = this.auth.httpOptionsWithAuth } = {}): Observable<any> {
    return this.http.get(`${host}/api/vlm2/borrower/business/category`, httpOptionsWithAuth);
  }

  getBusinessClassification({ host = this.hostService.endpoint, httpOptionsWithAuth = this.auth.httpOptionsWithAuth } = {}): Observable<any> {
    return this.http.get(`${host}/api/vlm2/borrower/business/classification`, httpOptionsWithAuth);
  }

  getBusinessPlatform({ host = this.hostService.endpoint, httpOptionsWithAuth = this.auth.httpOptionsWithAuth } = {}): Observable<any> {
    return this.http.get(`${host}/api/vlm2/borrower/business/platform`, httpOptionsWithAuth);
  }

  getLoanPurpose({ host = this.hostService.endpoint, loanType, httpOptionsWithAuth = this.auth.httpOptionsWithAuth }): Observable<any> {
    return this.http.get(`${host}/api/vlm2/loan/${loanType}/purpose`, httpOptionsWithAuth);
  }

  getAllLoanPurpose({ host = this.hostService.endpoint, httpOptionsWithAuth = this.auth.httpOptionsWithAuth } = {}): Observable<any> {
    return this.http.get(`${host}/api/vlm2/loan/loan-purpose`, httpOptionsWithAuth);
  }

  getTerms({ host = this.hostService.endpoint, httpOptionsWithAuth = this.auth.httpOptionsWithAuth } = {}): Observable<any> {
    return this.http.get(`${host}/api/vlm2/loan/terms`, httpOptionsWithAuth);
  }

  getCities({ host = this.hostService.endpoint, httpOptions = this.auth.httpOptions } = {}): Observable<any> {
    return this.http.get(`${host}/api/vlm2/cities`, httpOptions);
  }

  getBarangay({ host = this.hostService.endpoint, city, httpOptions = this.auth.httpOptions }): Observable<any> {
    return this.http.get(`${host}/api/vlm2/${city}/brgy`, httpOptions);
  }


  getNotifications(): Observable<any> {
    const host = this.hostService.endpoint;
    const httpOptionsWithAuth = this.auth.httpOptionsWithAuth;
    return this.http.get(`${host}/api/vlm2/borrower/notification`, httpOptionsWithAuth);
  }

  getFaq() {
    const host = this.hostService.endpoint;
    // eslint-disable-next-line max-len
    return this.http.get(`${host}/api/vlm2/borrower/faq`, this.auth.httpOptionsWithAuthWithoutJson);
  }

  submitCompleteProfilePicture({ host = this.hostService.endpoint, body }): Observable<any> {

    // eslint-disable-next-line max-len
    return this.http.post(`${host}/api/vlm2/borrower/upload/profile-image`, body, this.auth.httpOptionsWithAuthWithoutJson);
  }
  submitCompleteProfile({ host = this.hostService.endpoint, body }): Observable<any> {

    return this.http.post(`${host}/api/vlm2/borrower/update`, body, this.auth.httpOptionsWithAuth);
  }

  register(form): Observable<any> {
    const host = this.hostService.endpoint;

    form.deviceName = this.auth.deviceName;
    form.application = this.auth.platform;
    return this.http.post(`${host}/api/vlm2/borrower/add`, form, this.auth.httpOptionsWithAuth).pipe(
      tap(response => {
        const { secret } = response;

        /**
         * Set verification type
         */
        // this.localStorageService.set({ key: 'verification_type', value: 'verify' });
        this.localStorageService.set({ key: 'verification_type', value: 'register' });
        this.localStorageService.set({ key: 'email', value: form.email });
        this.localStorageService.set({ key: 'secret', value: secret });

      }));

  }

  reverify(form): Observable<any> {
    const host = this.hostService.endpoint;

    form.deviceName = this.auth.deviceName;
    form.application = this.auth.platform;
    return this.http.post(`${host}/api/vlm2/verifyAccount`, form, this.auth.httpOptionsWithAuth).pipe(
      tap(response => {
        const { secret } = response;

        /**
         * Set verification type
         */
        // this.localStorageService.set({ key: 'verification_type', value: 'verify' });
        this.localStorageService.set({ key: 'verification_type', value: 'email-reverification' });
        this.localStorageService.set({ key: 'email', value: form.email });
        this.localStorageService.set({ key: 'secret', value: secret });

      }));

  }

  validateCompleteProfile({ body, type }): Observable<any> {

    const host = this.hostService.endpoint;
    let httpOptions = this.auth.httpOptionsWithAuth;
    let path;
    switch (type) {
      case 'profile':
        path = 'profile';
        break;
      case 'work':
        path = 'work';
        break;
      case 'bank':
        path = 'bank';
        break;
      default:

        /**
         * Photo
         */
        httpOptions = this.auth.httpOptionsWithAuthWithoutJson;
        path = 'photo';
        break;
    }

    return this.http.post(
      `${host}/api/vlm2/borrower/validate/${path}`,
      body,
      this.auth.httpOptionsWithAuthWithoutJson
    );

  }

  forgotPassword({ host = this.hostService.endpoint, email }): Observable<any> {
    const deviceName = this.auth.deviceName;
    const body = { email, deviceName };
    return this.http.post(`${host}/api/vlm2/forgot/password`, body, this.auth.httpOptions).pipe(tap(
      response => {

        const { secret } = response;

        /**
         * Set email
         */
        new LocalStorageService().set({ key: 'email', value: email });

        /**
         * Set verification type
         */
        new LocalStorageService().set({ key: 'verification_type', value: 'forgot-password' });

        /**
         * Set secret
         */
        new LocalStorageService().set({ key: 'secret', value: secret });

      }));
  }

  getLoanActivityHistory({ host = this.hostService.endpoint, httpOptionsWithAuth = this.auth.httpOptionsWithAuth } = {}) {
    return this.http.get(`${host}/api/vlm2/borrower/history`, httpOptionsWithAuth);
  }

  getLoanHistory({ host = this.hostService.endpoint } = {}): Observable<any> {
    return this.http.get(`${host}/api/vlm2/borrower/loans`, this.auth.httpOptionsWithAuth);
  }

  getBorrowerDetails({ host = this.hostService.endpoint } = {}): Observable<any> {
    return this.http.get(`${host}/api/vlm2/borrower`, this.auth.httpOptionsWithAuth);
  }

  changeMpin({ host = this.hostService.endpoint, body }): Observable<any> {
    return this.http.post(`${host}/api/vlm2/borrower/mpin`, body, this.auth.httpOptionsWithAuth).pipe(tap(() => {
      this.isPinEnabled.next(body.enable);
    }));
  }

  getEmployment({ host = this.hostService.endpoint } = {}): Observable<any> {
    return this.http.get(`${host}/api/vlm2/borrower/employment`, this.auth.httpOptionsWithAuth);
  }

  getBusiness({ host = this.hostService.endpoint } = {}): Observable<any> {
    return this.http.get(`${host}/api/vlm2/borrower/business`, this.auth.httpOptionsWithAuth);
  }

  getBank({ host = this.hostService.endpoint } = {}): Observable<any> {
    return this.http.get(`${host}/api/vlm2/borrower/bank`, this.auth.httpOptionsWithAuth);
  }

  getListProvince({ host = this.hostService.endpoint, httpOptions = this.auth.httpOptions } = {}): Observable<any> {
    return this.http.get(`${host}/api/vlm2/provinces`, httpOptions);
  }

  submitProvince({ host = this.hostService.endpoint, httpOptions = this.auth.httpOptions, province }): Observable<any> {

    // eslint-disable-next-line max-len
    return this.http.post(`${host}/api/vlm2/filter/province`, province, httpOptions);
  }

  validateCompleteProfilePhoto({ body }): Observable<any> {

    const host = this.hostService.endpoint;
    return this.http.post(
      `${host}/api/vlm2/borrower/upload/profile-image`,
      body,
      this.auth.httpOptionsWithAuthWithoutJson
    );
  }

  validateCompleteProfilePersonal({ body }): Observable<any> {

    const host = this.hostService.endpoint;
    return this.http.post(
      `${host}/api/vlm2/borrower/personalInfo`,
      body,
      this.auth.httpOptionsWithAuthWithoutJson
    );
  }

  validateCompleteProfileIncome({ body }): Observable<any> {

    const host = this.hostService.endpoint;
    return this.http.post(
      `${host}/api/vlm2/borrower/sourceOfIncome`,
      body,
      this.auth.httpOptionsWithAuthWithoutJson
    );
  }

  validateCompleteProfileBank({ bodyBank }): Observable<any> {

    const host = this.hostService.endpoint;
    return this.http.post(
      `${host}/api/vlm2/borrower/bankInfo`,
      bodyBank,
      this.auth.httpOptionsWithAuthWithoutJson
    );
  }

  AIProfile({ body }): Observable<any> {

    const host = this.hostService.endpoint;
    return this.http.post(
      `${host}/api/vlm2/borrower/profile/ai-filter`,
      body,
      this.auth.httpOptionsWithAuthWithoutJson
    );
  }

  getBorrowerLoanLabel({ host = this.hostService.endpoint } = {}): Observable<any> {
    return this.http.get(`${host}/api/vlm2/loan/loanLabel`, this.auth.httpOptionsWithAuth);
  }

  getDeleteAccountReason({ host = this.hostService.endpoint } = {}): Observable<any> {
    return this.http.get(`${host}/api/vlm2/borrower/deleteAccount/list`, this.auth.httpOptionsWithAuth);
  }

  getDeleteState(): Observable<any> {
    const host = this.hostService.endpoint;
    const httpOptionsWithAuth = this.auth.httpOptionsWithAuth;
    return this.http.get(`${host}/api/vlm2/borrower/deleteButton/state`, httpOptionsWithAuth);
  }

  deleteAccount({ body }): Observable<any> {
    const host = this.hostService.endpoint;
    return this.http.post(
      `${host}/api/vlm2/borrower/deleteAccount`,
      body,
      this.auth.httpOptionsWithAuthWithoutJson
    );
  }
}
