import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'mapToIterable'
})
export class MapToIterablePipe implements PipeTransform {

  transform(map: { [key: string]: any }) {
    if (!map) {
      return undefined;
    }

    const response = Object.keys(map)
      .map((key) => ({ key, value: map[key] }));
    return response;
  }

}
  