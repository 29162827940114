import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { forkJoin, of, Subject } from 'rxjs';
import { catchError, filter, finalize, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { AuthenticationService } from '../services/authentication.service';
import { BorrowerService } from '../services/borrower.service';
import { CookieService } from '../services/cookie.service';
import { GlobalService } from '../services/global.service';
import { LocalStorageService } from '../services/local-storage.service';
import * as Sentry from '@sentry/angular';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard  implements OnDestroy {

  isSubscriptionsDestroyed$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private auth: AuthenticationService,
    private router: Router,
    private borrower: BorrowerService,
    private localStorageService: LocalStorageService,
    private cookieService: CookieService,
    private global: GlobalService,
  ) { }

  ngOnDestroy() {
    this.isSubscriptionsDestroyed$.next(true);
    this.isSubscriptionsDestroyed$.unsubscribe();
  }

  canLoad() {

    if (!this.cookieService.getCookie('token').length) {
      this.router.navigate(['/login']);
    }

    return this.auth.isAuthenticated.pipe(
      filter(val => val !== null),
      take(1),
      map(
        isAuthenticated => {
          if (isAuthenticated) {
            this.setBorrowerDetails();
            return true;
          } else {
            this.router.navigate(['/login']);
            return false;
          }
        })
    );
  }

  async setBorrowerDetails() {
    /*
    // Fetch notifications
    this.borrower.getNotifications().pipe(
      takeUntil(this.isSubscriptionsDestroyed$),
      tap(response => {
        this.borrower.notifications.next(response);
      }),
      finalize(() => {
        this.borrower.isNotificationLoading$.next(false);
      })
    ).subscribe();
    */

    // Set borrower and details
    const { pinDetails } = this.global.localStorageKeys;
    forkJoin({
      borrowerDetails: this.auth.details.pipe(take(1)),
      loanStatus: this.auth.loanStatus.pipe(take(1)),
      loanProducts: this.auth.loanProducts.pipe(take(1)),
    }).pipe(
      takeUntil(this.isSubscriptionsDestroyed$),
      tap(async response => {

        const { borrowerDetails, loanStatus, loanProducts } = response;

        this.borrower.details.next(borrowerDetails);
        this.borrower.loanStatus.next(loanStatus);
        this.borrower.loanProducts.next(loanProducts);


        // Sentry.setUser({ email: borrowerDetails.email, id: borrowerDetails.id });
        this.borrower.isPinEnabled.next(borrowerDetails.mpin);
        const details = {
          isEnabled: borrowerDetails.mpin,
          email: borrowerDetails.email,
          fullName: `${borrowerDetails.firstName} ${borrowerDetails.lastName}`,
          profileImage: borrowerDetails.photo || borrowerDetails.profileImage || this.global.defaultAvatar
        };

        this.localStorageService.set({ key: pinDetails, value: JSON.stringify(details) });
      }
      ),
      tap(async () => {
        this.borrower.getNotifications().pipe(
          takeUntil(this.isSubscriptionsDestroyed$),
          take(1),
          tap(response => {
            this.borrower.notifications.next(response);
            this.borrower.isNotificationLoading$.next(false);
          })
        ).subscribe();
      }),
      tap(async () => {
        this.borrower.getDeleteState().pipe(
          takeUntil(this.isSubscriptionsDestroyed$),
          take(1),
          tap(response => {
            this.borrower.deleteState.next(response);
          })
        ).subscribe();
      }),
      catchError(() => of([])))
      .subscribe();
  }
}
