import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'generateFileThumbnail'
})
export class GenerateFileThumbnailPipe implements PipeTransform {

  transform(file: any): any {
    const { fileName, url } = file;
    const fileType = (/[.]/.exec(fileName)) ? /[^.]+$/.exec(fileName) : undefined;
    if (!fileType) {
      return '?';
    }
    switch (fileType[0].toLocaleLowerCase()) {
      case 'jpg':
      case 'jpeg':
      case 'gif':
        return `<img class="w-24 m-auto  object-contain rounded-md" src="${url}">`;
        break;
      case 'pdf':
        return '<img class="w-24 m-auto  object-contain rounded-md" src="assets/images/pdf.png">';
        break;
      case 'docx':
        return '<img class="w-24 m-auto  object-contain rounded-md" src="assets/images/docx.png">';
        break;
      default:
        break;
    }

  }

}
