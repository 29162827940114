import { Injectable } from '@angular/core';
import * as base64 from 'base-64';

// import { environment } from './../../environments/environment';

const environment = {
  production: false
};

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  constructor() { }

  set(config: any): Promise<any> {
    const { key, value } = config;
    return Promise.resolve().then(() => {
      if (environment.production) {
        localStorage.setItem(base64.encode(key), base64.encode(value));
      } else {
        localStorage.setItem(key, base64.encode(value));
      }
    });
  }

  get(config: any): Promise<any> {
    const { key } = config;
    const promise = new Promise((resolve, reject) => {
      let value;
      if (environment.production) {
        value = localStorage.getItem(base64.encode(key));
      } else {
        value = localStorage.getItem(key);
      }
      if (!value) {
        reject(`Localstorage(${key}) with that key is does not exists!`);
      } else {
        resolve(base64.decode(value));
      }
    });
    return promise;
  }

  remove(config: any): Promise<any> {
    const { key } = config;
    const promise = new Promise((resolve, reject) => {
      let value;

      if (environment.production) {
        value = localStorage.getItem(base64.encode(key));
      } else {
        value = localStorage.getItem(key);
      }
      if (!value) {
        // reject(`Localstorage(${key}) with that key is does not exists!`);
      } else {

        if (environment.production) {
          localStorage.removeItem(base64.encode(key));
          
        } else {
          localStorage.removeItem(key);
        }

        resolve(value);
      }
    });
    return promise;
  }


}
