/* eslint-disable max-len */
import { Injectable } from '@angular/core';
import { Router, NavigationExtras } from '@angular/router';
import { of, Subject,Observable,tap  } from 'rxjs';
import { catchError, map} from 'rxjs/operators';
import { BorrowerService } from '../services/borrower.service';
import { includes } from 'cypress/types/lodash';


@Injectable({
  providedIn: 'root'
})
export class PageGuardGuard  {
  isSubscriptionsDestroyed$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private router: Router,
    private borrower: BorrowerService,
  ) { }

  canActivate(): Observable<boolean> {
    return this.borrower.loanStatus.pipe(
      tap((response) => {
        // const allowed = ['on_process', 'incomplete_requirement', 'account_approved', 'pre_approved', 'for_gm_approval', 'for_marketing_approval', 'approved', 'released', 'claimed','matured','restructuring','active','rerestructuring'];
        // if (!allowed.includes(response.status)) {
        //   this.router.navigate(['/u/home']);
        // }
        const notAllowed = ['completed', 'disapproved', 'cancelled'];
        if(notAllowed.includes(response.status)) {
          this.router.navigate(['/u/home']);
        }
      })
    );
  }

}
