import { Injectable } from '@angular/core';
import { Route, Router, UrlSegment, UrlTree } from '@angular/router';
import { isPlatform } from '@ionic/angular';
import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { AuthenticationService } from '../services/authentication.service';
import { CookieService } from '../services/cookie.service';
import { LocalStorageService } from '../services/local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class AutoLoginGuard  {

  pinDetails: any;
  constructor(
    private auth: AuthenticationService,
    private router: Router,
    private cookieService: CookieService,

  ) {
    new LocalStorageService().get({ key: 'pin_details' }).then(res => {
      if (res) {
        this.pinDetails = JSON.parse(res);
      }
    }, () => { });

  }

  canLoad(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    if (!this.cookieService.getCookie('token')) {
      return true;
    }
    return this.auth.isAuthenticated.pipe(
      filter(val => val !== null),
      take(1),
      map(
        isAuthenticated => {
          if (isAuthenticated) {
            this.router.navigateByUrl('/u/home', { replaceUrl: true });
            return true;
          } else {

            if (this.pinDetails && this.pinDetails.isEnabled) {
              new LocalStorageService().get({ key: 'loginMethod' }).then(value => {
                switch (value) {
                  case 'loginWithEmailAndPassword':
                    this.router.navigateByUrl('/', { replaceUrl: true });
                    break;
                  default:
                    if (isPlatform('android')) {
                      this.router.navigateByUrl('/pin', { replaceUrl: true });
                    }
                    break;
                }
              }, error => {

              });

            }
            return true;
          }
        })
    );
  }
}
