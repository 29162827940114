import { Component, Input, OnInit } from '@angular/core';
import { GlobalService } from 'src/app/services/global.service';
import { UtilService } from 'src/app/services/util.service';

@Component({
  selector: 'app-footer-mobile',
  templateUrl: './footer-mobile.component.html',
  styleUrls: ['./footer-mobile.component.scss'],
})
export class FooterMobileComponent implements OnInit {

  @Input() footerContentClass: string;
  @Input() footerClass: string;
  @Input() linkClass: string = 'text-F9AC18';


  constructor(
    public global: GlobalService,
    public util: UtilService,
  ) { }

  ngOnInit() { }

}
