import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'customCurrencyPipe'
})
export class CustomCurrencyPipePipe implements PipeTransform {

    formatNumber(value) {
      value = value.toString();
    value = value.replace(/[^0-9\.]/g, '');
    const parts = value.split('.');
    parts[0] = parts[0].replace(/\d{1,3}(?=(\d{3})+(?!\d))/g, '$&,');
    if (parts[1] && parts[1].length > 2) {
      parts[1] = parts[1].substring(0, 2);
    }
    return parts.join('.');

  };


  applyFormatting(value) {
    const original = value;
    if (!value || value.length === 0) {
      return;
    }
    value = this.formatNumber(value);
    if (value !== original) {
      // element.val(value);
      // element.triggerHandler('input');
    }
    return value;
  };


  transform(value): any {
    return this.applyFormatting(value);
  }

}
