import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { retry, tap } from 'rxjs/operators';
import { AuthenticationService } from './authentication.service';
import { HostService } from './host.service';
import { LoadingService } from './loading.service';
import { LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class LoanService {

  private host;
  private httpOptionsWithAuth;

  constructor(
    private http: HttpClient,
    private hostService: HostService,
    private auth: AuthenticationService,
    private loading: LoadingService,
    private localStorageService: LocalStorageService,
  ) {
    this.host = this.hostService.endpoint;
    this.httpOptionsWithAuth = this.auth.httpOptionsWithAuth;
  }

  getLoanProducts({ host = this.hostService.endpoint, httpOptionsWithAuth = this.auth.httpOptionsWithAuth } = {}): Observable<any> {
    return this.http.get(`${host}/api/vlm2/loan/products`, httpOptionsWithAuth);
  }

  getMiscOptions(
    {
      loanType = null,
      host = this.hostService.endpoint,
      httpOptionsWithAuth = this.auth.httpOptionsWithAuth
    } = {}
  ): Observable<any> {
    return this.http.get(`${host}/api/vlm2/loan/${loanType}/option`, httpOptionsWithAuth);
  }

  getBankLists({ host = this.hostService.endpoint, httpOptionsWithAuth = this.auth.httpOptionsWithAuth } = {}): Observable<any> {
    return this.http.get(`${host}/api/vlm2/borrower/bank/list`, httpOptionsWithAuth);
  }

  getCivilStatusLists({ host = this.hostService.endpoint, httpOptionsWithAuth = this.auth.httpOptionsWithAuth } = {}): Observable<any> {
    return this.http.get(`${host}/api/vlm2/borrower/civilStatus/list`, httpOptionsWithAuth);
  }

  getGenderList({ host = this.hostService.endpoint, httpOptionsWithAuth = this.auth.httpOptionsWithAuth } = {}): Observable<any> {
    return this.http.get(`${host}/api/vlm2/borrower/gender/list`, httpOptionsWithAuth);
  }

  getSuffixList({ host = this.hostService.endpoint, httpOptionsWithAuth = this.auth.httpOptionsWithAuth } = {}): Observable<any> {
    return this.http.get(`${host}/api/vlm2/borrower/suffix/list`, httpOptionsWithAuth);
  }

  removeAttachment({ host = this.hostService.endpoint, category, index }) {
    const body = {
      category, index
    };
    return this.http.post(`${host}/api/vlm2/loan/attachments/remove`, body, this.auth.httpOptionsWithAuth);
  }

  renewLoan({ host = this.hostService.endpoint } = {}) {
    return this.http.post(`${host}/api/vlm2/loan/renew`, {}, this.auth.httpOptionsWithAuth)
      .pipe(tap(
        {
          next: () => {
            this.localStorageService.remove({ key: 'is_disapproved_seen' });
          }
        }
      ));
  }

  getBusinessCategory({ host = this.hostService.endpoint } = {}) {
    return this.http.get(`${host}/api/vlm2/borrower/business/category`, this.auth.httpOptionsWithAuth);
  }

  reapplyLoan({ host = this.hostService.endpoint } = {}) {
    new LocalStorageService().remove({ key: 'is_disapproved_seen' });
    return this.http.post(`${host}/api/vlm2/loan/reapply`, {}, this.auth.httpOptionsWithAuth);
  }

  getClaimLoanTermsAndConditions({ host = this.hostService.endpoint } = {}) {
    return this.http.get(`${host}/api/vlm2/borrower/terms-conditions`, this.auth.httpOptionsWithAuth);
  }

  claimLoan(loanLabel) {
    const host = this.hostService.endpoint;
    return this.http.post(`${host}/api/vlm2/loan/${loanLabel}/claim`, {}, this.auth.httpOptionsWithAuth);
  }

  cancelLoan({ host = this.hostService.endpoint, loanLabel, body }) {
    return this.http.post(`${host}/api/vlm2/loan/${loanLabel}/cancel`, body, this.auth.httpOptionsWithAuth);
  }

  async getAmorrtizationSchedule({ host = this.hostService.endpoint } = {}) {
    await this.loading.simpleLoader({});
    return this.http.get(`${host}/api/vlm2/loan/print/scheduled-amortization`,
      { responseType: 'arraybuffer', headers: this.auth.httpOptionsWithAuth.headers })
      .pipe(tap(() => {
        this.loading.dismissLoader();
      }));
  }

  async getDisclousureStatement({ host = this.hostService.endpoint } = {}) {
    await this.loading.simpleLoader({});
    return this.http.get(`${host}/api/vlm2/loan/print/disclosure-statement`,
      { responseType: 'arraybuffer', headers: this.auth.httpOptionsWithAuth.headers })
      .pipe(tap(() => {
        this.loading.dismissLoader();
      }));
  }

  async getTermsAndConditions({ host = this.hostService.endpoint } = {}) {
    await this.loading.simpleLoader({});
    return this.http.get(`${host}/api/vlm2/loan/print/terms-and-conditions`,
      { responseType: 'arraybuffer', headers: this.auth.httpOptionsWithAuth.headers })
      .pipe(tap(() => {
        this.loading.dismissLoader();
      }));
  }

  async getBankAccountVerification({ host = this.hostService.endpoint } = {}) {
    await this.loading.simpleLoader({});
    return this.http.get(`${host}/api/vlm2/loan/print/bank-authorization`,
      { responseType: 'arraybuffer', headers: this.auth.httpOptionsWithAuth.headers })
      .pipe(tap(() => {
        this.loading.dismissLoader();
      }));
  }

  async getEmploymentVerification({ host = this.hostService.endpoint } = {}) {
    await this.loading.simpleLoader({});
    return this.http.get(`${host}/api/vlm2/loan/print/employment-verification`,
      { responseType: 'arraybuffer', headers: this.auth.httpOptionsWithAuth.headers })
      .pipe(tap(() => {
        this.loading.dismissLoader();
      }));
  }

  getLoanDetails({ host = this.hostService.endpoint } = {}): Observable<any> {
    return this.http.get(`${host}/api/vlm2/loan/status`, this.auth.httpOptionsWithAuth);
  }

  getAmortization({ host = this.hostService.endpoint } = {}): Observable<any> {
    return this.http.get(`${host}/api/vlm2/loan/scheduled-amortization`, this.auth.httpOptionsWithAuth);
  }

  /**X
   * Load borrower loan data
   * /api/vlm2/borrower/employment
   * /api/vlm2/borrower/business
   * /api/vlm2/borrower/character-reference
   * /api/vlm2/loan/attachment
   */
  getButtonsState({ host = this.hostService.endpoint } = {}): Observable<any> {
    return this.http.get(`${host}/api/vlm2/loan/status`, this.auth.httpOptionsWithAuth);
  }
  getAttachment({ host = this.hostService.endpoint, loanLabel }): Observable<any> {
    return this.http.get(`${host}/api/vlm2/loan/${loanLabel}/attachment`, this.auth.httpOptionsWithAuth);
  }

  getCharacterReference({ host = this.hostService.endpoint } = {}): Observable<any> {
    return this.http.get(`${host}/api/vlm2/borrower/character-reference`, this.auth.httpOptionsWithAuth);
  }

  getCoborrower({ host = this.hostService.endpoint } = {}): Observable<any>{
    return this.http.get(`${host}/api/vlm2/borrower/co-borrower`, this.auth.httpOptionsWithAuth);
  }

  getBusiness({ host = this.hostService.endpoint } = {}): Observable<any> {
    return this.http.get(`${host}/api/vlm2/borrower/business`, this.auth.httpOptionsWithAuth);
  }

  getEmployment({ host = this.hostService.endpoint } = {}): Observable<any> {
    return this.http.get(`${host}/api/vlm2/borrower/employment`, this.auth.httpOptionsWithAuth);
  }

  /**
   * Submit loan
   * /{loanLabel}/loan/update
   * /{loanLabel}/income/update
   * /{type}/reference/update
   * /{loanLabel}/attachments/update
   * /{loanLabel}/ai-filter
   * /{loanLabel}/application/update
   * /{loanLabel}/character/update
   * /{loanLabel}/files/update
   * /{loanLabel}/aifilter
   */
  loanApplicationUpdate({ host = this.hostService.endpoint, loanLabel, body }): Observable<any> {
    return this.http.post(`${host}/api/vlm2/loan/${loanLabel}/application/update`, body, this.auth.httpOptionsWithAuth);
  }

  loanIncomeUpdate({ host = this.hostService.endpoint, loanLabel, body }): Observable<any> {
    return this.http.post(`${host}/api/vlm2/loan/${loanLabel}/profit/update`, body, this.auth.httpOptionsWithAuth);
  }

  loanReferenceUpdate({ host = this.hostService.endpoint, loanLabel, body }): Observable<any> {
    return this.http.post(`${host}/api/vlm2/loan/${loanLabel}/character/update`, body, this.auth.httpOptionsWithAuth);
  }

  loanAttachmentsUpdate({ host = this.hostService.endpoint, loanLabel, body }): Observable<any> {
    return this.http.post(
      `${host}/api/vlm2/loan/${loanLabel}/files/update`, body, this.auth.httpOptionsWithAuthWithoutJson);
  }

  loanAiFilter({ host = this.hostService.endpoint, loanLabel, body }): Observable<any> {
    return this.http.post(`${host}/api/vlm2/loan/${loanLabel}/aifilter`, body, this.auth.httpOptionsWithAuth);
  }

  loanUpdate({ host = this.hostService.endpoint, loanLabel, body }): Observable<any> {
    return this.http.post(`${host}/api/vlm2/loan/${loanLabel}/loan/update`, body, this.auth.httpOptionsWithAuth);
  }

  incomeUpdate({ host = this.hostService.endpoint, loanLabel, body }): Observable<any> {
    return this.http.post(`${host}/api/vlm2/loan/${loanLabel}/income/update`, body, this.auth.httpOptionsWithAuth);
  }

  referenceUpdate({ host = this.hostService.endpoint, loanLabel, body }): Observable<any> {
    return this.http.post(`${host}/api/vlm2/loan/${loanLabel}/reference/update`, body, this.auth.httpOptionsWithAuth);
  }

  attachmentsUpdate({ host = this.hostService.endpoint, loanLabel, body }): Observable<any> {
    return this.http.post(
      `${host}/api/vlm2/loan/${loanLabel}/attachments/update`, body, this.auth.httpOptionsWithAuthWithoutJson);
  }

  aiFilter({ host = this.hostService.endpoint, loanLabel, body }): Observable<any> {
    return this.http.post(`${host}/api/vlm2/loan/${loanLabel}/ai-filter`, body, this.auth.httpOptionsWithAuth);
  }

  validateLoanApplication({ host = this.hostService.endpoint, loanLabel, body }): Observable<any> {
    /**
     * Type
     * loanLabel: salary, personal, lite, small business, business
     */
    return this.http.post(`${host}/api/vlm2/loan/${loanLabel}/validate`, body, this.auth.httpOptionsWithAuth);
  }

  validateLoanReference({ host = this.hostService.endpoint, type, body }): Observable<any> {
    return this.http.post(`${host}/api/vlm2/loan/validate/${type}/reference`, body, this.auth.httpOptionsWithAuth);
  }

  // getDefaultAttachments(type): Observable<any> {
  //   return this.http.get(`${host}/api/vlm2/loan/${type}/attachment`, this.auth.httpOptionsWithAuth);
  // }
  validateLoanAttachments({ host = this.hostService.endpoint, loanLabel, body }): Observable<any> {
    // loanLabel: salary, personal, lite, small business, business
    // eslint-disable-next-line max-len
    return this.http.post(`${host}/api/vlm2/loan/validate/${loanLabel}/attachments`, body, this.auth.httpOptionsWithAuthWithoutJson);
  }

  submitLoanAttachments({ host = this.hostService.endpoint, loanLabel, body }): Observable<any> {
    // eslint-disable-next-line max-len
    return this.http.post(`${host}/api/vlm2/loan/${loanLabel}/attachments/update`, body, this.auth.httpOptionsWithAuthWithoutJson);
  }

  validateTab({ host = this.hostService.endpoint } = {}): Observable<any> {
    // eslint-disable-next-line max-len
    return this.http.get(`${host}/api/vlm2/borrower/validate/tab`, this.auth.httpOptionsWithAuth);
  }

  validateTabInfo({ host = this.hostService.endpoint } = {}): Observable<any> {
    // eslint-disable-next-line max-len
    return this.http.get(`${host}/api/vlm2/borrower/validate/tabInfo`, this.auth.httpOptionsWithAuth);
  }

}
