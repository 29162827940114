import { Component, Input, OnInit } from '@angular/core';
import { LoadingService } from 'src/app/services/loading.service';
import { UtilService } from 'src/app/services/util.service';

@Component({
  selector: 'app-register-province',
  templateUrl: './register-province.page.html',
  styleUrls: ['./register-province.page.scss'],
})
export class RegisterProvincePage implements OnInit {

  @Input() closeCallback: any;

  constructor(
    private util: UtilService,
    private loading: LoadingService,
  ) {}

  ngOnInit() {
  }

  async submit() {
    await this.close(false);
  }

  close(triggerCallback = true) {

    if (this.util.currentModal) {
      this.util.currentModal.dismiss().then(() => {
        this.util.currentModal = null;
      });
    }

    if (triggerCallback && this.closeCallback) {
      this.closeCallback();
    }

  }

}
