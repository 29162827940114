/* eslint-disable max-len */
/* eslint-disable @angular-eslint/use-lifecycle-interface */

import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { AlertController, isPlatform } from '@ionic/angular';
import { Router } from '@angular/router';
import { GlobalService } from './services/global.service';
import { BorrowerService } from './services/borrower.service';
import { AuthenticationService } from './services/authentication.service';
import { LocalStorageService } from './services/local-storage.service';
import { HostService } from './services/host.service';
// import Swal from 'sweetalert2';
import Swal from 'sweetalert2/src/sweetalert2.js';
import { catchError, filter, of, repeat, Subject, takeUntil, tap } from 'rxjs';
import { UtilService } from './services/util.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})

export class AppComponent implements OnInit, OnDestroy {

  pageTransitionAnimation = true;
  borrowerDetails: any;
  notifications = 0;
  sideBarMenu = [];
  isAuth = false;
  isThereNewNotif = false;

  idleTimer;
  idleTime = 300000;
  isPageLoaded = false;

  pingTimer;
  pingTime = 30000;

  deleteButton: boolean;

  isSubscriptionsDestroyed$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private router: Router,
    private borrower: BorrowerService,
    public global: GlobalService,
    private alertController: AlertController,
    private auth: AuthenticationService,
    private authService: AuthenticationService,
    private util: UtilService,
    private localStorageService: LocalStorageService,
    public hostService: HostService,

  ) {
    const { isMaintainanceMode } = global.localStorageKeys;
    this.sideBarMenu = global.sideBarMenu;
    /**
     * Detect safari platform
     */
    const ua = navigator.userAgent.toLowerCase();
    // this.localStorageService.remove({ key: 'notifications' });
    if (ua.indexOf('safari') !== -1) {
      if (ua.indexOf('chrome') > -1) {
      } else {
        setTimeout(() => {
          this.showWebBrowserIncompatibility({ message: `You're using a web browser we don't support. Use Google Chrome, Mozilla Firefox or Opera to have a better experience on Vidalia Lending.`, header: 'Improve Your Experience', subHeader: ' ' });
        }, 5000);
      }
    }

    this.localStorageService.get({ key: isMaintainanceMode }).then(res => {
      if (res !== 'true') {
        this.fetch();
      }
    }, _err => {
      this.localStorageService.set({ key: isMaintainanceMode, value: false });
    });

  }

  @HostListener('window:keyup', ['$event'])
  @HostListener('window:scroll', ['$event'])
  @HostListener('mousedown', ['$event'])
  @HostListener('touchstart', ['$event'])
  @HostListener('click', ['$event'])
  onEvent(_event) {
    if (this.isAuth) {
      this.resetTimer();
    }
  }

  async ngOnInit() {

    /**
     * Clear verification countdown cache if its not active
     */
    this.getBorrowerDetails();
    this.getNotificationNumber();
    this.getDeleteButtonState();

    window.addEventListener('offline', this.updateConnection);
    window.addEventListener('online', this.updateConnection);

    // this.fetch();

  }

  ngOnDestroy() {
    const { idleTimestampKey } = this.global.localStorageKeys;
    this.localStorageService.set({ key: idleTimestampKey, value: new Date().getTime() });
    this.isSubscriptionsDestroyed$.next(true);
    this.isSubscriptionsDestroyed$.unsubscribe();
  }

  fetch() {

    const { autoLogoutKey, isMaintainanceMode } = this.global.localStorageKeys;

    /**
     * 1st Observable
     */
    this.authService.isAuthenticated
      .pipe(
        takeUntil(this.isSubscriptionsDestroyed$),
        tap({
          next: async response => {

            if (response) {
              switch (this.router.url) {
                case '/forgot-password':
                  this.isAuth = false;
                  break;
                default:
                  this.isAuth = true;
                  break;
              }

              this.resetTimer();

              /**
               * Check Server status
               */
              /**
               * 2nd Observable
               */
              this.auth.getServerStatus(this.auth.endpoint)
                .pipe(repeat({ delay: this.pingTime }))
                .pipe(
                  takeUntil(this.isSubscriptionsDestroyed$),
                  tap({
                    next: async res => {
                      if (res) {
                        const { status } = res;
                        if (!status) {
                          this.localStorageService.set({ key: isMaintainanceMode, value: true });
                          window.location.replace('/maintenance-page');
                          if (!isPlatform('mobileweb') && isPlatform('android')) {
                            this.util.showToastNative('Maintenance Mode');
                          } else {
                            this.util.showToast({ title: 'Maintenance Mode', text: 'Vidalia is temporarily undergoing maintenance.', icon: 'warning' });
                          }
                        } else {
                          this.localStorageService.set({ key: isMaintainanceMode, value: false });
                        }
                      }
                    }
                  }
                  ),
                  catchError(err => of([])))
                .subscribe();

              /**
               * Validate token
               */
              this.auth.validateToken(this.auth.endpoint)
                .pipe(repeat({ delay: this.pingTime }))
                .pipe(
                  takeUntil(this.isSubscriptionsDestroyed$),
                  catchError(async err => {
                    const { status } = err;
                    if (status === 401) {
                      window.removeEventListener('beforeunload', this.util.beforeUnload, true);
                      const autoLogoutConfig = {
                        type: 'session_expired',
                        title: `Session Expired`,
                        message: 'Your session has expired. Please login again.',
                      };
                      await this.localStorageService.set({ key: autoLogoutKey, value: JSON.stringify(autoLogoutConfig) });
                      await this.auth.clearLoginCache();
                      window.location.replace('/login');

                      if (!isPlatform('mobileweb') && isPlatform('android')) {
                        this.util.showToastNative('Session expired');
                      } else {
                        this.util.showToast({ title: 'Session expired', text: 'Please log in again.', icon: 'info' });
                      }
                    }
                    return of([]);
                  }))
                .subscribe();
            } else {
              this.isAuth = false;
            }
          }
        }
        ),
        catchError(err => of([]))
      ).subscribe();
  }

  async showWebBrowserIncompatibility(params) {
    const { isImproveyourExperienceHidden } = this.global.localStorageKeys;
    const { message, header } = params;
    const alert = await this.alertController.create({
      header,
      message,
      backdropDismiss: false,
      mode: 'ios',
      keyboardClose: false,
      cssClass: ['alert-title:{color:#ff3737}'

      ],
      buttons: [
        {
          text: 'Close',
          role: 'cancel',
          cssClass: 'secondary'
        },
        {
          text: 'Do not Show Again',
          handler: async () => {
            this.localStorageService.set({ key: isImproveyourExperienceHidden, value: true });
            await alert.dismiss();
          },
          cssClass: 'secondary'
        },

      ]
    });
    await this.localStorageService.get({ key: isImproveyourExperienceHidden }).then(async res => {

      if (res === 'true') {
        return true;
      }
      await alert.present();
    }, async err => {
      this.localStorageService.set({ key: isImproveyourExperienceHidden, value: false });
      await alert.present();
    });

  }

  updateConnection() {
    if (navigator.onLine) {
      const conn = (navigator as any).connection;
      const effectiveType = conn.effectiveType;
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const toastConfig = Swal.mixin({
        toast: true,
        position: 'bottom',
        showConfirmButton: false,
        timer: 5000,
        width: '600'
      });

      toastConfig.fire({
        padding: 0,
        background: '#F9AC18',
        color: 'white',
        html: 'Your internet connection was restored.',
      });

    } else {

      // eslint-disable-next-line @typescript-eslint/naming-convention
      const toastConfig = Swal.mixin({
        toast: true,
        position: 'bottom',
        showConfirmButton: false,
        timer: 99999,
        width: '600'
      });

      toastConfig.fire({
        padding: 0,
        background: '#242526',
        customClass: {
          htmlContainer: 'swal2-html-container-white-text',
        },
        html: 'You are currently offline.',
      });
    }
  }

  getBorrowerDetails() {
    this.borrower.details.pipe(
      takeUntil(this.isSubscriptionsDestroyed$),
      filter(response => response !== null),
      tap({
        next: response => {
          this.borrowerDetails = response;
        }
      }),
      catchError(err => {
        if (err.status !== 200) {
          this.util.showAllErrorToast(err);
        }
        return of([]);
      }))
      .subscribe();
  }

  getNotificationNumber() {
    this.borrower.notifications.pipe(
      takeUntil(this.isSubscriptionsDestroyed$),
      filter(response => response !== null),
      tap({
        next: response => {
          this.notifications = response.length;
        }
      }),
      catchError(err => {
        if (err.status !== 200) {
          this.util.showAllErrorToast(err);
        }
        return of([]);
      }))
      .subscribe();
  }
  /**
   * Show rate us after on_process
   * Show if status is account approved
   */

  async showRateUs() {
    const { isRateUsSeen } = this.global.localStorageKeys;
    this.borrower.loanStatus
      .pipe(
        takeUntil(this.isSubscriptionsDestroyed$),
        tap({
          next: async res => {
            const { status } = res;
            if (status === 'account_approved') {
              this.localStorageService.get({ key: isRateUsSeen }).then(response => {
                response = (response === 'true');
                if (!response) {
                  this.util.rateUs();
                }
              }, () => {
                this.localStorageService.set({ key: isRateUsSeen, value: false }).then(() => {
                  this.util.rateUs();
                });
              });
            }
          }
        }),
        catchError(err => {
          if (err.status !== 200) {
            this.util.showErrorAlert(err);
          }
          return of([]);
        })
      ).subscribe();

  }

  async logout() {
    const alert = await this.alertController.create({
      header: 'Logging Out',
      message: `Are you sure you want to logout?`,
      backdropDismiss: false,
      mode: 'ios',
      keyboardClose: false,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary'
        }, {
          text: 'Logout',
          handler: async () => {
            // window.removeEventListener('beforeunload', this.utility.beforeUnload, true);
            await this.auth.logout(this.auth.endpoint);
          }
        }
      ]
    });
    await alert.present();
  }

  getDeleteButtonState() {
    this.borrower.deleteState.pipe(
      takeUntil(this.isSubscriptionsDestroyed$),
      catchError(err => {
        if (err.status !== 200) {
          this.util.showAllErrorToast(err);
        }
        return of([]);
      })
    ).subscribe(response => {
      this.deleteButton = response;
    });
  }

  resetTimer() {
    clearTimeout(this.idleTimer);
    this.idleTimer = setTimeout(() => {

      let timer = 0;
      let secondsLeft = 20;
      let timerFunction;
      Swal.fire({
        customClass: {
          closeButton: 'swal-close',
          title: 'swal-title-denied',
          htmlContainer: 'swal-container',
        },
        showCloseButton: true,
        showConfirmButton: false,
        imageUrl: './../../assets/images/iddle-error.png',
        imageWidth: 250,
        title: 'Are you still there?',
        html: 'You will automatically logout from your account if you’ve been idle for a minute. <b></b>',
        imageAlt: 'idle',
        didOpen: () => {
          const b = Swal.getHtmlContainer().querySelector('b');
          timerFunction = setInterval(async () => {
            if (secondsLeft > 0) {
              secondsLeft = secondsLeft - 1;
            }
            timer = secondsLeft;
            b.textContent = timer.toString();
            if (timer <= 0) {
              await clearInterval(timerFunction);
              this.util.logoutWhenIdle();
              clearTimeout(this.idleTimer);

            }
          }, 1000);
        },
        didClose: async () => {
          clearInterval(timerFunction);
          this.resetTimer();
        }
      });

    }, this.idleTime);
  }

}
