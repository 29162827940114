import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { isPlatform } from '@ionic/angular';
import { GlobalService } from '../services/global.service';
import { LocalStorageService } from '../services/local-storage.service';


@Injectable({
  providedIn: 'root'
})
export class IntroGuard  {

  platform: any;

  constructor(
    private router: Router,
    private localStorageService: LocalStorageService,
    private global: GlobalService) {
  }

  async canLoad() {
    const { isIntroSeen } = this.global.localStorageKeys;
    let hasSeenIntro = '';
    await this.localStorageService.get({ key: isIntroSeen }).then(
      (res: any) => {
        hasSeenIntro = res;

      },
      () => {
        this.localStorageService.set({ key: isIntroSeen, value: 'false' });
      });

    if (hasSeenIntro && (hasSeenIntro === 'true')) {
      return true;
    } else {
      if (!isPlatform('mobileweb') && isPlatform('android')) {
        if (this.router.url !== '/intro') {
          this.router.navigateByUrl('/intro', { replaceUrl: true });
        }
      }
      return true;
    }
  }
}
