import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';
@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  public isLoading = false;
  constructor(
    public loadingController: LoadingController
  ) { }

  async simpleLoader({ message = 'Loading please wait...', duration = 30000 }) {
    await this.loadingController.create({ message, duration }).then((response) => {
      this.isLoading = true;
      response.present();
    });
  }

  dismissLoader(id = null) {
    if (id) {
      this.loadingController.dismiss({ id })
        .then(_ => {
          this.isLoading = false;
          // console.log('loading closed:',id);
        })
        .catch(_ => { });
    } else {
      this.loadingController.dismiss()
        .then(_ => {
          this.isLoading = false;
          // console.log('loading closed:','No ID');
        })
        .catch(_ => {

        });
    }

  }

}
