import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MapToIterablePipe } from 'src/app/pipes/map-to-iterable.pipe';
import { DisplayPipe } from 'src/app/pipes/display.pipe';
import { SanitizePipe } from 'src/app/pipes/sanitize.pipe';
import { DefaultHrefPipe } from 'src/app/pipes/default-href.pipe';
import { FooterComponent } from 'src/app/components/footer/footer.component';
import { FomatBytesPipe } from 'src/app/pipes/fomat-bytes.pipe';
import { IsArrayPipe } from 'src/app/pipes/is-array.pipe';
import { IsObjectPipe } from 'src/app/pipes/is-object.pipe';
import { ObjectToArrayPipe } from 'src/app/pipes/object-to-array.pipe';
import { NgOtpInputModule } from 'ng-otp-input';
import { PhoneMaskDirective } from 'src/app/directives/phone-mask.directive';
import { CurrencyMaskDirective } from 'src/app/directives/currency-mask.directive';
import { HeaderComponent } from 'src/app/components/header/header.component';
import { GenerateFileThumbnailPipe } from 'src/app/pipes/generate-file-thumbnail.pipe';
import { WhatFileTypePipe } from 'src/app/pipes/what-file-type.pipe';
import { RemoveCurrencyFormatPipe } from 'src/app/pipes/remove-currency-format.pipe';
import { FooterMobileComponent } from 'src/app/components/footer-mobile/footer-mobile.component';
import { FooterTwoComponent } from 'src/app/components/footer-two/footer-two.component';
import { CustomCurrencyPipePipe } from 'src/app/pipes/custom-currency-pipe.pipe';



@NgModule({
  declarations: [
    ObjectToArrayPipe,
    IsObjectPipe,
    IsArrayPipe,
    FomatBytesPipe,
    FooterComponent,
    FooterMobileComponent,
    FooterTwoComponent,
    HeaderComponent,
    MapToIterablePipe,
    DisplayPipe,
    SanitizePipe,
    DefaultHrefPipe,
    PhoneMaskDirective,
    CurrencyMaskDirective,
    GenerateFileThumbnailPipe,
    WhatFileTypePipe,
    RemoveCurrencyFormatPipe,
    CustomCurrencyPipePipe,
  ],
  exports: [
    ObjectToArrayPipe,
    IsObjectPipe,
    IsArrayPipe,
    FomatBytesPipe,
    FooterComponent,
    FooterMobileComponent,
    FooterTwoComponent,
    HeaderComponent,
    MapToIterablePipe,
    DisplayPipe,
    SanitizePipe,
    DefaultHrefPipe,
    NgOtpInputModule,
    PhoneMaskDirective,
    CurrencyMaskDirective,
    GenerateFileThumbnailPipe,
    WhatFileTypePipe,
    RemoveCurrencyFormatPipe,
    CustomCurrencyPipePipe,
  ],
  imports: [
    CommonModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]

})
export class SharedModule { }
