/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable max-len */
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class GlobalService {

  server = environment.server;

  serverName = environment.serverName;

  versionCode = environment.versionCode;

  version = environment.version;

  defaultAvatar = 'assets/images/default-avatar.jpg';

  imagePlaceholder = 'assets/images/placeholder.png';

  currentYear = new Date().getFullYear();

  sideBarMenu = [{
    title: 'Dashboard',
    step: 'n-a',
    link: '/u/home',
    icon: 'assets/icon/dashboard.svg',
    iconActive: 'assets/icon/dashboard-web-white.svg',
    classRefresh: 'data-refresh',
  },
  {
    title: 'Messages',
    step: 'n-a',
    link: '/u/notifications',
    icon: 'assets/icon/mail.svg'
  },
  // {
  //   title: 'Profile',
  //   step: 'avatar-button',
  //   link: '/u/profile',
  //   icon: 'assets/icon/profile.svg'
  // },
  {
    title: 'Loan History',
    step: 'n-a',
    link: '/u/loan-history',
    icon: 'assets/icon/loan-history.svg'
  },
  // {
  //   title: 'History Logs',
  //   step: 'n-a',
  //   link: '/u/history-logs',
  //   icon: 'assets/icon/clock-desktop.svg'
  // },
  // {
  //   title: 'About',
  //   step: 'n-a',
  //   link: '/u/about',
  //   icon: 'assets/icon/info.svg'
  // },
  {
    title: 'Contact Us',
    step: 'n-a',
    link: '/u/contact',
    icon: 'assets/icon/contact.svg'
  },
  {
    title: 'FAQ',
    step: 'n-a',
    link: '/u/faq',
    icon: 'assets/icon/faq-web.svg'
  },
  {
    title: 'Logout',
    link: '',
    icon: 'assets/icon/logout.svg'
  }];

  drawerMenu = [{
    title: 'Pin Setting',
    step: 'n-a',
    link: '/u/profile/pin-settings',
    icon: 'assets/icon/lock.svg',
    classRefresh: 'data-refresh',
  },
  {
    title: 'Loan History',
    step: 'n-a',
    link: '/u/loan-history',
    icon: 'assets/icon/loan-history.svg'
  },
  {
    title: 'FAQ',
    step: 'n-a',
    link: '/u/faq',
    icon: 'assets/icon/faq-web.svg'
  },
  {
    title: 'Rate Us',
    step: 'n-a',
    link: '',
    icon: 'assets/icon/star.svg'
  }];

  maxBirthdatePicker = `${new Date().getFullYear() - 18}-01-01`;
  minBirthdatePicker = `${new Date().getFullYear() - 100}-01-01`;

  localStorageKeys = {
    isMaintainanceMode: 'is_maintainance_mode',
    isWelcomeHidden: 'is_welcome_hidden',
    autoLogoutKey: 'auto_logout',
    pinDetails: 'pin_details',
    timeleft: 'timeleft',
    secret: 'secret',
    emailKey: 'email',
    borrowerDetails: 'borrower_details',
    loanStatus: 'loan_status',
    idleTimestampKey: 'idle_timestamp',
    isAppClosed: 'is_app_closed',
    resetPasswordToken: 'reset_token',
    verificationType: 'verification_type',
    notificationKey: 'notifications',
    isLoanActive: 'is_loan_active',
    tokenKey: 'token',
    userKey: 'user',
    isDisapprovedSeen: 'is_disapproved_seen',
    isImproveyourExperienceHidden: 'is_improve_your_experience_hidden',
    isRateUsSeen: 'is_rate_us_seen',
    isIntroSeen: 'is_intro_seen'
  };

}


