import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { catchError, of, Subject, takeUntil, tap } from 'rxjs';
import { BorrowerService } from 'src/app/services/borrower.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {

  @Input() title: string;

  isThereNewNotif = true;

  isSubscriptionsDestroyed$: Subject<boolean> = new Subject<boolean>();

  constructor(private router: Router, private borrower: BorrowerService) {
  }

  ngOnInit() {
    this.fetch();
  }

  ngOnDestroy() {
    this.isSubscriptionsDestroyed$.next(true);
    this.isSubscriptionsDestroyed$.unsubscribe();
  }

  fetch() {
    this.borrower.notifications.pipe(
      takeUntil(this.isSubscriptionsDestroyed$),
      tap((notifications: any) => {
        this.isThereNewNotif = notifications && notifications.length;
      }),
      catchError(() => of([])))
      .subscribe();
  }

  goto(url) {
    this.router.navigateByUrl(url);
  }
}
