import { CurrencyPipe } from '@angular/common';
import { Directive, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';


@Directive({
  selector: '[formControlName][appCurrencyMask]'
})
export class CurrencyMaskDirective {

  constructor(public ngControl: NgControl) { }

  @HostListener('ngModelChange', ['$event'])
  onModelChange(event) {
    this.onInputChange(event, false);
  }

  @HostListener('keydown.backspace', ['$event'])
  keydownBackspace(event) {
    this.onInputChange(event.target.value, true);
  }

  formatNumber(value) {
    value = value.toString();
    value = value.replace(/[^0-9\.]/g, '');
    const parts = value.split('.');
    parts[0] = parts[0].replace(/\d{1,3}(?=(\d{3})+(?!\d))/g, '$&,');
    if (parts[1] && parts[1].length > 2) {
      parts[1] = parts[1].substring(0, 2);
    }
    return parts.join('.');
  };


  applyFormatting(value) {
    const original = value;
    if (!value || value.length === 0) {
      return;
    }
    value = this.formatNumber(value);
    if (value !== original) {
      return value;

    }
    return original;

  };

  onInputChange(event, backspace) {
    this.ngControl.valueAccessor.writeValue(this.applyFormatting(event));

  }
}
