import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'objectToArray'
})
export class ObjectToArrayPipe implements PipeTransform {

  transform(item): unknown {
    const objToArr = [];
    for (const key of Object.keys(item)) {

      objToArr.push(item);
    };

    return objToArr;
  }

}
