import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { StatusBar, Style, BackgroundColorOptions } from '@capacitor/status-bar';
import { isPlatform, ModalController, NavParams } from '@ionic/angular';
import { SwiperOptions, Zoom } from 'swiper';
import { SwiperComponent } from 'swiper/angular';
import SwiperCore from 'swiper';

import { UtilService } from 'src/app/services/util.service';

SwiperCore.use([Zoom]);

@Component({
  selector: 'app-image-modal',
  templateUrl: './image-modal.page.html',
  styleUrls: ['./image-modal.page.scss'],
})
export class ImageModalPage implements OnInit {
  @ViewChild('slider', { static: false }) slider?: SwiperComponent;

  @Input() url: string;
  @Input() fileName: string;

  defaultImage = 'assets/images/placeholder.png';


  sliderOpts: SwiperOptions = {
    zoom: {
      maxRatio: 8
    },
    centeredSlides: true,
  };


  constructor(
    private util: UtilService,
  ) {

  }

  ngOnInit() {
  }

  ionViewWillEnter() {
    if (!isPlatform('mobileweb') && isPlatform('android')) {
      try {
        StatusBar.setStyle({ style: Style.Light });
        const opts: BackgroundColorOptions = {
          color: '#2c272dd6',
        };
        StatusBar.setBackgroundColor(opts);
      } catch (error) {

      }

    }
  }

  ionViewDidLeave() {
    if (!isPlatform('mobileweb') && isPlatform('android')) {
      try {
        StatusBar.setStyle({ style: Style.Light });
        const opts: BackgroundColorOptions = {
          color: '#FFFFFF',
        };
        StatusBar.setBackgroundColor(opts);
      } catch (error) {

      }

    }
  }



  close() {
    if (this.util.currentModal) {
      try {
        this.util.currentModal.dismiss().then(() => {
          this.util.currentModal = null;
        });
      } catch (error) { }

    }
  }

}
