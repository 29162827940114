/* eslint-disable @typescript-eslint/naming-convention */
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Device } from '@capacitor/device';
import { BehaviorSubject, forkJoin, from, Observable, of, Subject, Subscription } from 'rxjs';
import { catchError, finalize, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { HostService } from './host.service';
import { LoadingService } from './loading.service';
import { AlertController } from '@ionic/angular';
import { CookieService } from './cookie.service';
import { LocalStorageService } from './local-storage.service';
import Swal from 'sweetalert2';
import { GlobalService } from './global.service';

@Injectable({
  providedIn: 'root'
})

export class AuthenticationService implements OnDestroy {

  isAuthenticated: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  isResetPasswordGranted: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);

  /**
   * The values will be transfered to borrower.service.ts via auth guard
   */
  details: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  loanStatus: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  isPinEnabled: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  loanProducts: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  token = '';
  secret = '';
  email = '';
  userInfo = '';
  httpOptionsWithAuthWithoutJson = {};
  httpOptionsWithAuth: any;
  httpOptionsMultipartWithAuth: any;
  httpOptionsJsonOnly = {
    headers: new HttpHeaders({
      'Accept-Type': 'application/json',
    })
  };
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    })
  };
  uuid = '';
  deviceName: string;
  platform: string;
  endpoint = '';
  isSubscriptionsDestroyed$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private http: HttpClient,
    private host: HostService,
    private global: GlobalService,
    private loading: LoadingService,
    private cookiesService: CookieService,
    private localStorageService: LocalStorageService,
    private alertController: AlertController,
    private router: Router

  ) {

    /**
    * Get device model
    */
    Device.getId().then((response: any) => {
      const { uuid } = response;
      this.deviceName = uuid;
    });

    Device.getInfo().then((response: any) => {
      const { platform } = response;
      this.platform = platform;
    });

    /**
     * Set local endpoint
     */
    this.endpoint = this.host.endpoint;

    this.initAuth();
  }

  ngOnDestroy() {
    this.isSubscriptionsDestroyed$.next(true);
    this.isSubscriptionsDestroyed$.unsubscribe();
  }

  async initAuth() {
    const host = this.endpoint;
    const global = this.global;
    const { versionCode } = global;
    const { tokenKey, resetPasswordToken } = global.localStorageKeys;
    const tokenValue = await this.cookiesService.getCookie(tokenKey);

    /**
     * Version checking
     */
    await this.loading.simpleLoader({});
    this.http.get(`${host}/api/vlm2/check/version`)
      .pipe(
        takeUntil(this.isSubscriptionsDestroyed$),
        tap(async (res: any) => {
          const appVersion = parseInt(versionCode, 10);
          const latestVersion = parseInt(res, 10);
          if (appVersion < latestVersion) {
            const alert = await this.alertController.create({
              header: 'New Version is Now Available',
              // eslint-disable-next-line max-len
              message: 'A new version is available. Please update before continuing.',
              backdropDismiss: false,
              mode: 'ios',
              keyboardClose: false,
              buttons: [
                {
                  text: 'Update',
                  handler: async () => {
                    window.open('market://details?id=com.vidalia.lending', '_blank');
                    await alert.dismiss();
                  }
                }
              ]
            });
            await alert.present();
          }

        }),
        catchError(err => of([])),
        finalize(() => {
          this.loading.dismissLoader();
        })
      )
      .subscribe();

    /**
     * Check if token exist
     */
    if (!tokenValue) {
      this.isAuthenticated.next(false);
      return true;
    }


    /**
     * Fetch borrower detail, loanstatus and loan products
     */
    this.token = tokenValue;
    this.setHeaderWithToken(tokenValue);
    await this.loading.simpleLoader({});
    forkJoin(
      {
        loanProducts: this.http.get(`${host}/api/vlm2/loan/products`, this.httpOptionsWithAuth),
        loanStatus: this.http.get(`${host}/api/vlm2/loan/status`, this.httpOptionsWithAuth),
        borowerDetails: this.http.get(`${host}/api/vlm2/borrower`, this.httpOptionsWithAuth),
      }
    ).pipe(
      takeUntil(this.isSubscriptionsDestroyed$),
      take(1),
      tap(async (response: any) => {

        const { borowerDetails, loanStatus, loanProducts } = response;
        this.details.next(borowerDetails);
        this.loanStatus.next(loanStatus);
        this.loanProducts.next(loanProducts);

        /**
         * If borrower is disapproved
         */
        if (loanStatus && loanStatus.status === 'disapproved') {
          this.localStorageService.get({ key: 'is_disapproved_seen' }).then(isDisapprovedSeen => {
            if (!isDisapprovedSeen) {
              this.showDenied(loanStatus.loanStatusMessage, borowerDetails.email, loanStatus.status, loanStatus.labelColor);
            }
          }, _err => {
            this.showDenied(loanStatus.loanStatusMessage, borowerDetails.email, loanStatus.status, loanStatus.labelColor);
          });
        }
        this.isAuthenticated.next(true);

      }),
      catchError(() => of([])),
      finalize(() => {
        this.loading.dismissLoader();
      }))
      .subscribe();


    /**
    * Verify reset password token
    */
    let email;
    await this.localStorageService.get({ key: 'email' }).then(
      res => {
        email = res;
      }, () => { });

    this.localStorageService.get({ key: resetPasswordToken })
      .then(resetPasswordTokenKeyRes => {

        const body = { token: resetPasswordTokenKeyRes, email };

        this.isResetPasswordGranted.next(true);

        /**
         * Verify reset password token
         */
        const verifyTokenSubscription = this.http.post(
          `${host}/api/vlm2/verify/token`,
          body,
          this.httpOptionsWithAuth
        ).pipe(
          takeUntil(this.isSubscriptionsDestroyed$),
          tap(() => {
            this.isResetPasswordGranted.next(true);
          }),
          catchError(err => {
            if (err.status === 401) {
              this.isResetPasswordGranted.next(false);
              this.router.navigateByUrl('/forgot-password');
            }
            return of([]);
          }))
          .subscribe();

      }, () => {
        this.isResetPasswordGranted.next(false);
      });

  }

  setHeaderWithToken(value) {

    this.httpOptionsWithAuth = {

      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${value}`
        }
      )
    };

    this.httpOptionsMultipartWithAuth = {
      headers: new HttpHeaders(
        {
          // 'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${value}`
        }
      )
    };

    this.httpOptionsWithAuthWithoutJson = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${value}`
      })
    };

  }

  refreshToken(host = this.endpoint, event = null): Observable<any> {

    return forkJoin(
      {
        loanStatus: this.http.get(`${host}/api/vlm2/loan/status`, this.httpOptionsWithAuth),
        borrowerDetails: this.http.get(`${host}/api/vlm2/borrower`, this.httpOptionsWithAuth)
      }
    ).pipe(
      tap(
        async res => {

          const { loanStatus, borrowerDetails } = res;

          this.localStorageService.set({ key: 'borrower_details', value: JSON.stringify(borrowerDetails) });
          this.localStorageService.set({ key: 'loan_status', value: JSON.stringify(loanStatus) });

        }
      )
    );
  }

  pinLogin({ host = this.endpoint, email, pin }) {

    /**
      * Set email for verification later
      */
    this.localStorageService.set({ key: 'email', value: email });
    const credentials = { email, pin };
    return this.http.post(`${host}/api/vlm2/login/mpin`, credentials, this.httpOptions,)
      .pipe(
        tap(
          (response: any) => {
            if (response && response.secret) {
              this.localStorageService.set({ key: 'secret', value: response.secret });
              this.localStorageService.set({ key: 'verification_type', value: 'verify' });
            }
          }
        )
      );
  }

  login({ host = this.endpoint, email, password, global = this.global }): Observable<any> {
    const { verificationType, emailKey, secret } = global.localStorageKeys;

    /**
     * Set email for verification later
     */
    this.localStorageService.set({ key: emailKey, value: email });

    const credentials = { email, password, deviceName: this.deviceName };

    // BugDefender
    // Bugfender.log({email, password:'******', deviceName: this.deviceName});
    return this.http.post(`${host}/api/vlm2/login`, credentials, this.httpOptions,).pipe(
      map((data: any) => data),
      switchMap(data => {
        const returnThis = this.localStorageService.set({ key: 'secret', value: data.secret });

        /**
         * Set verification type
         */
        this.localStorageService.set({ key: verificationType, value: 'verify' });
        return from(returnThis);
      })
    );
  }

  clearLoginCache(global = this.global) {

    const { secret,
      emailKey,
      borrowerDetails,
      loanStatus,
      idleTimestampKey,
      isAppClosed,
      notificationKey,
      verificationType,
      timeleft,
      tokenKey,
      userKey,

    } = global.localStorageKeys;

    this.cookiesService.deleteCookie(tokenKey);

    this.localStorageService.remove({ key: userKey });
    this.localStorageService.remove({ key: secret });
    this.localStorageService.remove({ key: emailKey });
    this.localStorageService.remove({ key: borrowerDetails });
    this.localStorageService.remove({ key: loanStatus });
    this.localStorageService.remove({ key: idleTimestampKey });
    this.localStorageService.remove({ key: isAppClosed });
    this.localStorageService.remove({ key: notificationKey });
    this.localStorageService.remove({ key: verificationType });
    this.localStorageService.remove({ key: timeleft });
  }

  async logout(host) {
    await this.loading.simpleLoader({});
    this.clearLoginCache();
    this.http.post(
      `${host}/api/vlm2/borrower/logout`,
      {},
      this.httpOptionsWithAuth)
      .pipe(
        takeUntil(this.isSubscriptionsDestroyed$),
        tap({
          next: async () => {
            this.localStorageService.get({ key: 'pin_details' }).then(async pinDetails => {
              const details = await JSON.parse(pinDetails);

              if (details.isEnabled) {
                window.location.replace('/pin');
              } else {
                window.location.replace('/login');
              }
            });
          }
        }),
        catchError(err => {
          if (err.status !== 200) {

          }
          return of([]);
        }), finalize(() => {
          this.loading.dismissLoader();
        }))
      .subscribe();
  }


  /**
   * Toke are issued here
   *
   */
  verifyOTP({
    otp,
    secret,
    verificationType = 'null',
    global = this.global,
    httpOptions = this.httpOptions,
    endpoint = this.endpoint,
    form
  }): Observable<any> {
    const { tokenKey } = global.localStorageKeys;
    const content = { otp, secret, form };

    let verificationPath = '/verify';
    
    if (verificationType === 'forgot-password') { (verificationPath = '/forgot/password/verify'); };
    if (verificationType === 'register') { (verificationPath = '/register/verify'); };
    if (verificationType === 'email-reverification') { (verificationPath = '/verify/now'); };

    return this.http.post(`${endpoint}/api/vlm2${verificationPath}`, content, httpOptions)
      .pipe(
        tap(
          (response) => {
            const { accessToken } = response;

            if (!!accessToken) {

              this.setHeaderWithToken(accessToken);
              this.token = accessToken;
              
              /**
               * Set token to secure storage
               */
              if (verificationType === 'forgot-password') {
                this.localStorageService.set({ key: 'reset_token', value: accessToken });
              }
              else {

                this.cookiesService.setCookie({ name: tokenKey, value: accessToken, secure: true });

              }
            }

          }
        )
      );
  }

  resendOTP({ host = this.endpoint, email, secret, verificationType = null }): Observable<any> {

    const content = { email, secret };

    if (verificationType === 'forgot-password') {

      return this.http.post(`${host}/api/vlm2/forgot/password/verify/resend`, content, this.httpOptions);

    } else {

      return this.http.post(`${host}/api/vlm2/verify/resend`, content, this.httpOptions);

    }

  }

  resetPassword(
    {
      host = this.endpoint,
      email,
      token,
      password,
      confirmPassword,
      httpOptions = this.httpOptions,
      global = this.global
    }
  ): Observable<any> {

    const body = { email, token, password, password_confirmation: confirmPassword };
    const { resetPasswordToken, verificationType } = global.localStorageKeys;
    return this.http.post(`${host}/api/vlm2/reset/password/${token}`, body, httpOptions)
      .pipe(
        tap(
          () => {
            // this.localStorageService.set({ key: 'token', value: response.accessToken });
            this.localStorageService.remove({ key: resetPasswordToken });
            this.localStorageService.remove({ key: verificationType });
            // this.setHeaderWithToken(response.accessToken);
            // this.isResetPasswordGranted.next(false);
          }
        )
      );
  }

  getServerStatus(host): Observable<any> {
    // eslint-disable-next-line max-len
    return this.http.get(`${host}/api/vlm2/status`);
  }

  validateToken(host, global = this.global): Observable<any> {
    const { tokenKey } = global.localStorageKeys;
    const tokenValue = this.cookiesService.getCookie(tokenKey);
    this.setHeaderWithToken(tokenValue);
    return this.http.get(`${host}/api/vlm2/borrower/validate/token`, this.httpOptionsWithAuth);
  }

  showDenied(message = null, email, status, labelColor) {
    Swal.fire({
      allowOutsideClick: false,
      customClass: {
        closeButton: 'swal-close',
        title: (labelColor === 'warning') ? 'swal-title-review' : 'swal-title-denied',
        htmlContainer: 'swal-container text-black',
      },
      showCloseButton: true,
      showConfirmButton: false,
      imageUrl: (labelColor === 'warning') ? './../../assets/images/mobile-prelogin.png' : './../../assets/images/application-denied.png',
      imageWidth: 250,
      title: (labelColor === 'warning') ? 'REAPPLY NOW!' : 'SORRY!',
      html: message,
      // imageAlt: 'SORRY!',
    }).then((result) => {
      const x = {
        email,
        value: true
      };
      this.localStorageService.set({ key: this.global.localStorageKeys.isDisapprovedSeen, value: JSON.stringify(x) });
    });
  }

}

