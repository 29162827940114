import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'removeCurrencyFormat'
})
export class RemoveCurrencyFormatPipe implements PipeTransform {

  transform(value): any {

    if (!value) {
      return 0;
    };

    if(typeof value === 'string'){
      value.replace(/[^0-9\.-]+/g, '');
    }

    return parseFloat(value);

  }

}
