import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AuthenticationService } from '../services/authentication.service';
import { LoadingService } from '../services/loading.service';
import { UtilService } from '../services/util.service';

import * as Sentry from '@sentry/angular';
import { LocalStorageService } from '../services/local-storage.service';
import { GlobalService } from '../services/global.service';
import { isPlatform } from '@ionic/angular';

@Injectable()
export class ErrorHandlingService implements HttpInterceptor {

  constructor(
    private router: Router,
    private util: UtilService,
    private auth: AuthenticationService,
    private loading: LoadingService,
    private global: GlobalService
  ) { }

  intercept(httpRequest: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const { autoLogoutKey, isMaintainanceMode } = this.global.localStorageKeys;
    return next.handle(httpRequest).pipe(
      tap(

        {
          error: async err => {
            // Sentry.setContext('Error Handling Service', { errors: { err }, code: err.status });
            const { name } = err;
            switch (err.status) {

              /**
               * Client side error responses
               */
              case 401:
                const blackListed = ['You have 3 attempts left', 'You have 2 attempts left', 'You have 1 attempts left'];
                if (err.error.message !== 'Invalid email or password' || blackListed.includes(err.error.message)) {
                  window.removeEventListener('beforeunload', this.util.beforeUnload, true);
                  await this.auth.clearLoginCache();
                  window.location.replace('/login');
                }
                break;
              case 403:
                if (err.error.message !== 'Your account is unverified') {
                  window.removeEventListener('beforeunload', this.util.beforeUnload, true);
                  await this.auth.clearLoginCache();
                  window.location.replace('/login');
                }
                break;
              case 413:
                this.util.showErrorAlert(
                  {
                    header: 'Oops!',
                    subHeader: 'Sorry, something went wrong',
                    message: 'We\'re working on getting this fixed as soon as we can.',
                    refresh: false
                  }
                );
                break;
              case 0:
                this.util.showErrorAlert(
                  {
                    header: 'Oops!',
                    subHeader: 'Sorry, something went wrong',
                    message: 'Poor network connection detected. Please try again later.',
                    refresh: true
                  }
                );
                break;

              /**
              * Server side error responses
              */
              case 500:

                // this.utility.showErrorAlert({ message: err.message, refresh: false });
                break;

              case 503:

                /**
                 * Maintenance Page
                 */
                this.router.navigateByUrl('/maintenance-page', { replaceUrl: true });
                break;
              case 504:

                this.util.showErrorAlert(
                  {
                    header: 'Slow Internet!',
                    message: 'The connection has timed out. Kindly check you network or restart the application and try again. (504)'
                  }
                );

                break;

            }

          }

        }

      )

    );

  }

}
