/* eslint-disable max-len */
import { Injectable } from '@angular/core';
import { environment } from './../../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class HostService {

  env = environment.production;
  endpoint = environment.host;
  server = '';

  constructor() {

    if (environment.production) {

      const style = 'font-weight: bold; font-size: 30px;color: red;';
      console.log(`%cSTOP!`, style);
      console.log(`%cThis is a browser feature intended for developers. If someone told you to copy-paste something here to enable a feature or "hack" someone's account, it is a scam and will give them access to your account.
      `, 'font-weight: bold; font-size: 15px;');
      this.server = 'Oscar';

    }

    else {
      
      this.server = 'Sun';
      // const style = 'font-weight: bold; font-size: 50px;color: red; text-shadow: 3px 3px 0 rgb(217,31,38) , 6px 6px 0 rgb(226,91,14) , 9px 9px 0 rgb(245,221,8) , 12px 12px 0 rgb(5,148,68) , 15px 15px 0 rgb(2,135,206) , 18px 18px 0 rgb(4,77,145) , 21px 21px 0 rgb(42,21,113)';
      const style = 'font-weight: bold; font-size: 30px;color: red;';
      console.log('%cDevelopment Mode', 'font-weight: bold; font-size: 30px;color: green;');
      console.log(`%cAPI Host: ${this.endpoint}`, style);

    }

  }

}
